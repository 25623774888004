import { ADD_FUTSAL_ID } from "../actionTypes/actionTypes";


const addFutsalId = (id:Number) => {
    return {
        type: ADD_FUTSAL_ID,
        payload:id
    };
};

// const deleteItem = () => {
//     return {
//         type: DELETE_ITEM,
//     };
// };

export { addFutsalId };