import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { endPoints } from "../utils/endPoints";
import { axiosInstance } from "../utils/axiosInstance";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CheckoutHeader from "./navigation/checkout-navigation/CheckoutHeader";
import BookingTypeSkeleton from "./skeletonLoaders/BookingTypeSkeleton";
import { toast, ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";

function BookingType() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const { futsalId } = useParams();
  const [selectedOption, setSelectedOption] = useState<null | string>(
    "oneTimeBooking"
  );
  const [futsalData, setFutsalData] = useState<any | null>({});
  const [isLoaded, setLoaded] = useState(true);

  useEffect(() => {
    const verifyCookie = async () => {
      const token=localStorage.getItem("token")

      if (!token) {
        toast.error("session expired")
      }
      const { data } = await axiosInstance.post(
          endPoints.USER_AUTHENTICATION,
          {token},
          { withCredentials: true }
      );
      const { status, user, name } = data;
      if (!status) {
        toast.info("Please Login");
        localStorage.removeItem("token")
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/bookingType", title: "Booking Type Page" });
    fetchFutsalData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 2000);
  }, []);

  const fetchFutsalData = async () => {
    try {
      const endPoint = endPoints.GET_FUTSAL;
      const response = await axiosInstance.get(`${endPoint}${futsalId}`);
      setFutsalData(response.data.futsal);
      setLoaded(false);

      console.log("get futsals1", response);
    } catch (error) {
      console.error("Error fetching futsal data:", error);
    }
  };

  const handleClose = () => {
    window.history.back();
  };

  const handleOptionClick = (value: null | string) => {
    setSelectedOption(value);
    setTimeout(() => {
      // Scroll to the bottom of the page
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 1000);
    console.log("selected", value);
  };

  //next-btn
  const handleNextBtn = () => {
    console.log("Selected Option:", selectedOption);
    switch (selectedOption) {
      case "oneTimeBooking":
        navigate(`/oneTimeBooking/${futsalId}`);
        console.log("clicked", selectedOption);
        break;
      case "fullDayBooking":
        window.location.href = "/";
        break;
      case "permanentBooking":
        navigate(`/permanentBooking/${futsalId}`);
        break;
    }
  };

  function bookNowHandle() {
    navigate(`/bookingType/${futsalId}`);
  }

  return (
    <>
      <CheckoutHeader
        stepNumber={1}
        stepTitle={"Select Booking"}
        handleClose={handleClose}
      />

      <div className="date-time-container" style={{ justifyContent: "center" }}>
        <div className="date-time-booking-type">
          <form>
            <label className="container">
              <input
                type="radio"
                name="booking"
                value="oneTimeBooking"
                onChange={() => handleOptionClick("oneTimeBooking")}
                checked={selectedOption === "oneTimeBooking"}
              />
              One-Time Booking
              <span className="checkmark"></span>
              <p>
                Reserve the futsal court for a single session of your choice.
              </p>
            </label>
            {/*<label className="container">*/}
            {/*  <input type="radio" name="booking" value="fullDayBooking" onChange={() => handleOptionClick("fullDayBooking")} />*/}
            {/*  Full Day Booking*/}
            {/*  <span className="checkmark"></span>*/}
            {/*  <p>*/}
            {/*    Enjoy a full day of non-stop futsal action by reserving the*/}
            {/*    court from morning till evening.*/}
            {/*  </p>*/}
            {/*</label>*/}
            <label className="container">
              <input
                type="radio"
                name="booking"
                value="permanentBooking"
                onChange={() => handleOptionClick("permanentBooking")}
              />
              Permanent Booking
              <span className="checkmark"></span>
              <p>
                Secure your dedicated futsal court for extended periods,
                ensuring regular and convenient access for your team or group.
              </p>
            </label>
          </form>
        </div>

        <div className="date-time-booking-1">
          {isLoaded ? (
            <BookingTypeSkeleton />
          ) : (
            <>
              <div className="payment-container-border">
                <div className="payment-container">
                  {futsalData && (
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        {futsalData.name} ({futsalData.place})
                      </h3>
                      <p className="address">
                        {futsalData.address} {futsalData.place}
                      </p>
                    </div>
                  )}
                  <hr />
                </div>
              </div>
            </>
          )}
          <div className="next-btn" onClick={handleNextBtn}>
            <button>Next</button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default BookingType;
