import React, { useRef, FormEvent } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

function GetInTouchForm() {
    const form = useRef<HTMLFormElement | null>(null);

    const sendEmail = (e: FormEvent) => {
        e.preventDefault();

        if (form.current) {
            emailjs
                .sendForm(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID!,
                    process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
                    form.current,
                    process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
                )
                .then(
                    () => {
                        console.log('SUCCESS!');
                        toast.success('Form submitted successfully');
                        form.current?.reset();
                    },
                    (error) => {
                        toast.error('Oops something went wrong!');
                        console.log('FAILED...', error.text);
                        form.current?.reset();
                    }
                );
        }
    };

    return (
        <div className="get-in-touch-container">
            <div className="get-in-touch-form">
                <h3>Get in touch!</h3>
                <form ref={form} onSubmit={sendEmail}>
                    <div className="form-element">
                        <input type="text" id="from_name" name="from_name" required placeholder="Your name" />
                    </div>
                    <div className="form-element">
                        <input type="email" id="from_email" name="from_email" required placeholder="Your Email" />
                    </div>
                    <div className="form-element">
                        <input type="tel" id="from_number" name="from_number" required placeholder="Your Number" />
                    </div>
                    <button type="submit">APPLY</button>
                </form>
            </div>
            <div className="get-in-touch-text">
                <h3>Become A Partner</h3>
                <h1>Make your</h1>
                <h4>Bookings at</h4>
                <h2>GEARUP</h2>
            </div>
        </div>
    );
}

export default GetInTouchForm;
