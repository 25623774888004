import React, {useEffect, useState} from "react";
import MyBookingCard from "../interfaces/cards/MyBookingCard";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {User} from "../../interfaces/User";
import {toast} from "react-toastify";
import {axiosInstance} from "../../utils/axiosInstance";
import {endPoints} from "../../utils/endPoints";
import {useCookies} from "react-cookie";
import moment from "moment-timezone";

export interface Booking {
  bookingId: string;
  userName: string;
  email:string;
  futsalId:number;
  bookingTime:Date|null;
  playStartTime: Date|null;
  playEndTime: Date|null;
  startTime: string;
  endTime: string;
  courtName: string;
  price:number;
  status:String;
  futsalName:string;
  place:string

}
function MyBookings() {
  const currentDate = new Date();
  const [activeTab, setActiveTab] = useState(1);
  const [filterOption, setFilterOption] = useState('All')
  const [showDropdown, setShowDropdown] = useState(false)
  const [cookies, , removeCookie] = useCookies(["token"]);
  const [userEmail, setUserEmail] = useState();
  const [userDetails, setUserDetails] = useState<User>();
  const [loading, setLoading] = useState(true);
  const [bookings,setBookings]=useState<Booking[]>([])



  useEffect(() => {
    const verifyCookie = async () => {
      const token=localStorage.getItem("token")
      const { data } = await axiosInstance.post(
          endPoints.USER_AUTHENTICATION,
          {token},
          { withCredentials: true }
      );
      const { status, user, name } = data;
      setUserEmail(user)
      return status
          ? ""
          : localStorage.removeItem("token");
    };
    verifyCookie();
  }, [cookies, removeCookie]);

  useEffect(() => {
    if (userEmail) {
      axiosInstance.get(`${endPoints.GET_USER}${userEmail}`)
          .then(response => {
            if (response.data.status === 'ok') {
              setUserDetails(response.data.user);
              setLoading(false);
            }
          })
          .catch(error => {
            console.error('Error fetching user details:', error);
            setLoading(false);
          });
    }
  }, [userEmail]);

  useEffect(() => {
    if (userEmail) {
      axiosInstance.get(`${endPoints.GET_USER_BOOKINGS}${userEmail}`)
          .then(async response => {
            if (response.data.status === 'ok') {
              const fetchedBookings = response.data.allBookings;
              const updatedBookings = [];

              // Iterate through each booking to fetch futsal details
              for (const booking of fetchedBookings) {
                try {
                  const futsalResponse = await axiosInstance.get(`${endPoints.GET_FUTSAL}${booking.futsalId}`);
                  if (futsalResponse.data.status === 'ok') {
                    const updatedBooking = {
                      ...booking,
                      futsalName: futsalResponse.data.futsal.name,
                      place: futsalResponse.data.futsal.place
                    };
                    updatedBookings.push(updatedBooking);
                  }
                } catch (error) {
                  console.error('Error fetching futsal details:', error);
                }
              }

              // Update the state with the updated bookings
              setBookings(updatedBookings);
              setLoading(false);
            }
          })
          .catch(error => {
            console.error('Error fetching bookings details:', error);
            setLoading(false);
          });
    }
  }, [userEmail]);

  const filteredBookings = () => {
    const today = new Date();

    if (filterOption === 'All') {
      return bookings;
    } else if (filterOption === 'Last 5 Bookings') {
      return bookings.slice(0, 5);
    } else if (filterOption === 'Last Week') {
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);
    
      return bookings.filter((booking) => {
        const bookingDate = new Date(booking.playStartTime!);
        bookingDate.setUTCHours(bookingDate.getUTCHours() - 5);
        bookingDate.setUTCMinutes(bookingDate.getUTCMinutes() - 30);
        return bookingDate >= sevenDaysAgo && bookingDate <= today;
      });
    } else if (filterOption === 'Last Month') {
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDayLastMonth = new Date(firstDay);
      lastDayLastMonth.setDate(firstDay.getDate() - 1);
      const firstDayLastMonth = new Date(lastDayLastMonth.getFullYear(), lastDayLastMonth.getMonth(), 1)

      return bookings.filter((booking) => {
        const bookingDate = new Date(booking.playStartTime!);
        bookingDate.setUTCHours(bookingDate.getUTCHours() - 5);
        bookingDate.setUTCMinutes(bookingDate.getUTCMinutes() - 30);
        return bookingDate >= firstDayLastMonth && bookingDate <= lastDayLastMonth;
      });
      
    }
    else if (filterOption === 'Last 6 Months') {
      const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 5, 1);
    
      return bookings.filter((booking) => {
        const bookingDate = new Date(booking.playStartTime!);
        bookingDate.setUTCHours(bookingDate.getUTCHours() - 5);
        bookingDate.setUTCMinutes(bookingDate.getUTCMinutes() - 30);
        return bookingDate >= sixMonthsAgo && bookingDate <= today;
      });
    } else if (filterOption === 'This Year') {
      const startOfYear = new Date(today.getFullYear(), 0, 1);
    
      return bookings.filter((booking) => {
        const bookingDate = new Date(booking.playStartTime!);
        bookingDate.setUTCHours(bookingDate.getUTCHours() - 5);
        bookingDate.setUTCMinutes(bookingDate.getUTCMinutes() - 30);
        return bookingDate >= startOfYear && bookingDate <= today;
      });
    }
    return [];
  };


  const futureBookings = bookings.filter((booking) => {
    const bookingDate = new Date(booking.playStartTime!);
    bookingDate.setUTCHours(bookingDate.getUTCHours() - 5);
    bookingDate.setUTCMinutes(bookingDate.getUTCMinutes() - 30);

    return (
      bookingDate > currentDate ||
      (bookingDate.getDate() === currentDate.getDate() &&
        bookingDate.getHours() > currentDate.getHours())
    );
  });

  

  // const confirmedBookings = () => {}
  const handleTabClick = (tabNum: any) => {
    setActiveTab(tabNum);
    setFilterOption('All');
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleSelect = (option: string) => {
    setFilterOption(option);
    setShowDropdown(false);
  };
  
  const myBookingCard = (booking: any, index: any) => (
    <MyBookingCard
      key={index}
      futsalName={booking.futsalName}
      place={booking.place}
      court={booking.courtName}
      bookingId={booking.bookingId}
      date={new Date(booking.playStartTime).toLocaleDateString()}
      time={`${moment(booking.startTime, "HH:mm").format("hh:mm A")}-${moment(booking.endTime, "HH:mm").format("hh:mm A")}`}
    />
  );
  
  return (
    <div className="my-booking">
      <div className='my-booking-title'><h1>My Bookings</h1></div>
      <div className="my-booking-header">
       <div>
       <button
          onClick={() => handleTabClick(1)}
          className={`${activeTab === 1 ? 'active' : ''} ${'header-btn'}`}        >
          All
        </button>
        <button
          onClick={() => handleTabClick(2)}
          className={`${activeTab === 2 ? 'active' : ''} ${'header-btn'}`}        >
          Upcoming
        </button>
        {/* <button
          onClick={() => handleTabClick(3)}
          className={`${activeTab === 3 ? 'active' : ''} ${'header-btn'}`}        >
        
          Confirmed
        </button> */}
        <button
          onClick={() => handleTabClick(4)}
          className={`${activeTab === 4 ? 'active' : ''} ${'header-btn'}`}        >
        
          Cancelled
        </button>
       </div>
       
        {activeTab === 1 && (
        <div>
          <button className="my-booking-dropdown" onClick={toggleDropdown}>
            <span>{filterOption}</span>
            {showDropdown ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </button>
          {showDropdown && (
            <div className={`my-booking-dropdown-content ${
              showDropdown ? "show-dropdown" : ""
          }`}>
              <li onClick={() => handleSelect('Last 5 Bookings')}>Last 5 Bookings</li>
              <li onClick={() => handleSelect('Last Week')}>Last Week</li>
              <li onClick={() => handleSelect('Last Month')}>Last Month</li>
              <li onClick={() => handleSelect('Last 6 Months')}>Last 6 Months</li>
              <li onClick={() => handleSelect('This Year')}>This Year</li>
              <li onClick={() => handleSelect('All')}>All</li>
            </div>
          )}
        </div>
       )}
      </div>

     
      {activeTab === 1 && (
  <div className="my-booking-container">
     {filteredBookings().length > 0 ? (
      filteredBookings().map((booking, index) => (
        myBookingCard(booking, index)
      ))
    ) : (
      <p>No bookings found</p>
    )}
  </div>
)}

      {activeTab === 2 && (
        <div className="my-booking-container">
          {futureBookings.length > 0 ? (
      futureBookings.map((booking, index) => (
        myBookingCard(booking, index)
      ))
    ) : (
      <p>No upcoming bookings</p>
    )}
        </div>
      )}

      {/* {activeTab === 3 && (
        <div className="my-booking-container">
          {confirmedBookings.length > 0 ? (
            confirmedBookings.map((booking, index) => (
                bookingCard(booking, index)
            ))
          ) : (
            <p>No confirmed bookings</p>
          )}
        </div>
      )} */}

      {activeTab === 4 && (
        <div className="my-booking-container">
        {bookings.length > 0 ? (
            bookings.map((booking, index) => (
              myBookingCard(booking, index)
            ))
        ) : (
          <p>No cancelled bookings</p>
        )}
        </div>
      )} 
    </div>
  );
}

export default MyBookings;
