import React, { useState, useEffect } from "react";
import {
  Datepicker,
  DatepickerEvent,
} from "@meinefinsternis/react-horizontal-date-picker";
import { enIN } from "date-fns/locale";
import { endPoints } from "../../utils/endPoints";
import { axiosInstance } from "../../utils/axiosInstance";
import { Futsal } from "../../interfaces/Futsal";
import {useParams, useSearchParams} from "react-router-dom";
import { useUser } from "../../reducers/ui/userHook";
import PaymentModal from "../../payment-gateway/PaymentModel";
import {
  calculatePerPrice,
  calculateTotalDuration,
  calculateTotalPrice,
  fetchAvailableTimeSlots,
  getStartAndEndTime,
  handleButtonClickTime,
} from "./helper-functions/HelperFunctions";
import CheckoutHeader from "../navigation/checkout-navigation/CheckoutHeader";
import DateTimeSelector from "./checkout-card/date-time-picker/DateTimeSelector";
import BookingSummary from "./checkout-card/booking-summery-card/BookingSummary";
import PaymentCardSkeleton from "../skeletonLoaders/PaymentCardSkeleton";
import {toast} from "react-toastify";
import {useCookies} from "react-cookie";

interface Court {
  _id: string;
  courtName: string;
}

interface BookingSend {
  userName: string;
  email: string;
  futsalId: number;
  bookingTime: string | null;
  playStartTime: string | null;
  playEndTime: string | null;
  startTime: string;
  endTime: string;
  courtName: string;
  price: number;
  status: String;
}

interface hashModel {
  merchant_id: string;
  order_id: string;
  amount: number;
  currency: string;
}

function OneTimeBooking() {
  const [date, setDate] = React.useState(new Date());
  let [user, setUser] = useUser();
  const { futsalId } = useParams();
  const [selectCourts, setSelectCourts] = useState<Court[]>([]);
  const [courts, setCourts] = useState<Court[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedDateFormatted, setSelectedDateFormatted] = useState<
    string | null
  >(null);
  const [availability, setAvailability] = useState<any | null>({});
  const [selectedButton, setSelectedButton] = useState<string[]>([]);
  const [futsalData, setFutsalData] = useState<Futsal | null>();
  const [selectedCourt, setSelectedCourt] = useState<Court | null>(null);
  const [time, setTime] = useState(new Date());
  const [hash, setHash] = useState<hashModel>({
    merchant_id: "1226184",
    order_id: "GU-1011",
    amount: 0,
    currency: "LKR",
  });
  const [hashCode, setHashCode] = useState<string>();
  const [bookingData, setBookingData] = useState<BookingSend>({
    userName: "",
    email: "",
    futsalId: parseInt(futsalId!!),
    bookingTime:
      new Date(time.getTime() + 5.3 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, -5) + "Z",
    playStartTime: null,
    playEndTime: null,
    startTime: "",
    endTime: "",
    courtName: "",
    price: 0,
    status: "oneTimeBooking",
  });
  const [isLoaded, setLoaded] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const[userEmail,setUserEmail]=useState();

  useEffect(() => {
    console.log("Date-OneTimeBooking", time);
  }, []);

  useEffect(() => {
    const verifyCookie = async () => {
      const token=localStorage.getItem("token")

      if (!token) {
        toast.error("session expired")
      }
      const { data } = await axiosInstance.post(
          endPoints.USER_AUTHENTICATION,
          {token},
          { withCredentials: true }
      );
      const { status, user ,name} = data;
      setBookingData((prevData) => ({
        ...prevData,
        email: user,
        userName:name,
      }));
      setUserEmail(user)
      return status
          ?""
          :localStorage.removeItem("token");
    };
    verifyCookie();
  }, [cookies, removeCookie]);

  useEffect(() => {
    if (userEmail) {
      axiosInstance.get(`${endPoints.GET_USER}${userEmail}`)
          .then(response => {
            if (response.data.status === 'ok') {
              setUser(response.data.user);
            }
          })
          .catch(error => {
            console.error('Error fetching user details:', error);
          });
    }
  }, [userEmail]);

  useEffect(() => {
    console.log("prevSelected:", selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    fetchFutsalData();
    // setBookingData((prevData) => ({
    //   ...prevData,
    //   email: user.email,
    //   userName: user.name,
    // }));
  }, [user]);

  useEffect(() => {
    console.log("user", user);
    console.log("booking Data", bookingData);
  }, [bookingData]);

  useEffect(() => {
    if (selectedCourt != undefined) {
      setBookingData((prevData) => ({
        ...prevData,
        courtName: selectedCourt?.courtName,
      }));
    }
  }, [selectedCourt]);

  const addBooking = async () => {
    // try {
    //   const endPoint = endPoints.GET_PAYMENT_PORTAL_HASH;
    //   console.log("hash", hash);
    //   const response = await axiosInstance.get(endPoint, { params: hash });
    //   console.log(response.data);
    //   setHashCode(response.data);
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }
    try {
      const endPoint = endPoints.ADD_BOOKING;
      const response = await axiosInstance.post(endPoint, bookingData);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchFutsalData = async () => {
    try {
      const endPoint = endPoints.GET_FUTSAL;
      const response = await axiosInstance.get(`${endPoint}${futsalId}`);
      const futsal = response.data.futsal;
      console.log(futsal.courts);
      if (futsal.courts && futsal.courts.length > 0) {
        setSelectCourts([futsal.courts[0]]);
        setSelectedCourt(futsal.courts[0]);
      }
      setCourts(futsal.courts);
      setFutsalData(futsal);
      setLoaded(false);

      console.log("get futsals1", response);
    } catch (error) {
      console.error("Error fetching futsal data:", error);
    }
  };

  const times =
    selectedButton.length > 0 && getStartAndEndTime(selectedButton, date);

  useEffect(() => {
    if (futsalData) {
      setBookingData((prevData) => ({
        ...prevData,
        price: calculateTotalPrice(futsalData, selectCourts, selectedButton),
      }));

      setHash((prevData) => ({
        ...prevData,
        amount: calculateTotalPrice(futsalData, selectCourts, selectedButton),
      }));
    }
  }, [selectedButton]);

  useEffect(() => {
    if (times) {
      setBookingData((prevData) => ({
        ...prevData,
        startTime: times.formattedStartTime,
        endTime: times.formattedEndTime,
        playStartTime: times.playStartTime,
        playEndTime: times.playEndTime,
      }));
    }
  }, [selectedButton]);

  useEffect(() => {
    console.log("court", bookingData.courtName);
  }, [bookingData]);

  const handleButtonClickCourt = (clickedCourt: any) => {
    setSelectCourts([clickedCourt]);
    setSelectedCourt(clickedCourt);

    setBookingData((prevData) => ({
      ...prevData,
      courtName: clickedCourt.courtName,
    }));
    setSelectedButton([]);
    setAvailability({});
    fetchAvailableTimeSlots(
      date,
      clickedCourt,
      setAvailability,
      futsalData,
      bookingData
    );
  };

  const handleChangeDatePick = (d: DatepickerEvent) => {
    const [startValue] = d;
    const sriLankanTime = new Date(
      startValue!.getTime() - startValue!.getTimezoneOffset() * 60000
    );

    console.log("Srilankan Time", sriLankanTime);
    setDate(sriLankanTime);
    fetchAvailableTimeSlots(
      sriLankanTime,
      selectedCourt,
      setAvailability,
      futsalData,
      bookingData
    );

    const formattedDate = sriLankanTime.toLocaleDateString("en-IN", {
      day: "numeric",
      month: "short",
      weekday: "short",
    });

    setSelectedDate(sriLankanTime);
    setSelectedDateFormatted(formattedDate);
    setSelectedButton([]);
    console.log("sriLankanTime", sriLankanTime, "formattedDate", formattedDate);
  };

  const handleClose = () => {
    window.history.back();
  };

  const handleNextBtn = () => {
    addBooking();
  };

  if (hashCode) {
    return (
      <PaymentModal
        amount={hash.amount}
        email={user.email}
        hash={hashCode}
        phone={user.phoneNumber}
        name={user.name}
        orderId={hash.order_id}
        bookingData={bookingData}
      />
    );
  }

  return (
    <>
      <CheckoutHeader
        stepNumber={2}
        stepTitle={"Select Date & Time"}
        handleClose={handleClose}
      />

      <div className="date-time-container">
        <DateTimeSelector
          courts={courts}
          selectedDate={selectedDate}
          selectedButton={selectedButton}
          setSelectedButton={setSelectedButton}
          selectCourts={selectCourts}
          availability={availability}
          handleButtonClickCourt={handleButtonClickCourt}
          handleButtonClickTime={handleButtonClickTime}
          handleChangeDatePick={handleChangeDatePick}
          user={user}
          method={"OneTimeBooking"}
        />
         <BookingSummary
          selectedButton={selectedButton}
          selectCourts={selectCourts}
          calculatePerPrice={calculatePerPrice}
          calculateTotalPrice={calculateTotalPrice}
          calculateTotalDuration={calculateTotalDuration}
          futsalData={futsalData}
          handleNextBtn={handleNextBtn}
          selectedDateFormatted={selectedDateFormatted}
          selectedDate={selectedDate}
        />

      </div>
    </>
  );
}

export default OneTimeBooking;
