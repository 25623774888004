import React from 'react';

interface PolicyProps {
    isOpen: boolean;
    onClose: () => void;
    content: React.ReactNode;
}

export const Policy: React.FC<PolicyProps> = ({ isOpen, onClose, content }) => {
    if (!isOpen) return null;

    return (
        <div className="policy-popup-overlay">
            <div className="policy-popup-content">
                <div className="policy-popup-body">
                    {content}
                </div>
                <button className="policy-popup-close-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};
