import hours from '../../asserts/g-24.png'
import world from "../../asserts/g-world-2.png"
import cod from "../../asserts/g-cash-on-delivery-2.png"
const ThreeStepsBanner=()=>{
    return(
        <div className="steps-container">
            <div className="step-box">
                <img className="step-box-image" alt="24-hours" src={hours}/>
                <div className="step-box-wording">
                    <h1>BOOK</h1>
                    <h1>ANYTIME</h1>

                </div>
            </div>
            <div className="step-box">
                <img className="step-box-image" alt="24-hours" src={world}/>
                <div className="step-box-wording">
                    <h1>BOOK</h1>
                    <h1>ANYWHERE</h1>

                </div>
            </div>
            <div className="step-box">
                <img className="step-box-image" alt="24-hours" src={cod}/>
                <div className="step-box-wording">
                    <h1>BOOK</h1>
                    <h1>CHEAP</h1>

                </div>
            </div>
        </div>
    )
}
export default ThreeStepsBanner;