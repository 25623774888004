import moment from "moment-timezone";
import { Futsal } from "../../../interfaces/Futsal";
import React from "react";
import { axiosInstance } from "../../../utils/axiosInstance";
import { endPoints } from "../../../utils/endPoints";
import {User} from "../../../interfaces/User";
interface Court {
  _id: string;
  courtName: string;
}

const getStartAndEndTime = (selectedButton: string[], date: Date) => {
  console.log("selected-buttons", selectedButton);
  if (selectedButton.length > 0) {
    const startTime = selectedButton[0].split("-")[0];
    const endTime = selectedButton[selectedButton.length - 1].split("-")[1];
    const formattedStartTime = moment(startTime, ["hh:mm A"]).format("HH:mm");
    const formattedEndTime = moment(endTime, ["hh:mm A"]).format("HH:mm");

    console.log("selected-buttons time", formattedStartTime, formattedEndTime);
    const yearMonthDay = moment(date).format("YYYY-MM-DD");
    const playStartTime = moment
      .tz(
        yearMonthDay + " " + formattedStartTime.replace(".", ":"),
        "YYYY-MM-DD HH:mm",
        "Asia/Colombo"
      )
      .add(5.5, "hours")
      .utc()
      .format();
    const playEndTime = moment
      .tz(
        yearMonthDay + " " + formattedEndTime.replace(".", ":"),
        "YYYY-MM-DD HH:mm",
        "Asia/Colombo"
      )
      .add(5.5, "hours")
      .utc()
      .format();

    console.log(playStartTime, playEndTime);

    return {
      formattedStartTime,
      formattedEndTime,
      playStartTime,
      playEndTime,
    };
  }
};

const calculateTotalDuration = (selectedButton: string[]): string => {
  const timeSlotDuration = 60;
  const selectedDurationInMinutes = selectedButton.length * timeSlotDuration;

  const selectedDurationInHours = selectedDurationInMinutes / 60;
  const duration =
    selectedDurationInHours === 0 ? "" : selectedDurationInHours.toString();

  let returnDuration: string = "";

  if (duration !== "") {
    returnDuration =
      selectedDurationInHours === 1 ? `${duration} hour` : `${duration} hours`;
  }

  return returnDuration;
};

const calculateTotalPrice = (
  futsalData: Futsal | null,
  selectCourts: Court[],
  selectedButton: string[]
): number => {
  let totalPrice = 0;

  if (futsalData && futsalData.pricePerHour) {
    const { day, night } = futsalData.pricePerHour;

    selectCourts.forEach((court) => {
      selectedButton.forEach((startTime) => {
        // Extract the start hour from the startTime string
        const startHour = parseInt(startTime.split(":")[0], 10);
        const isPM = startTime.split(" ")[1].toUpperCase() === "PM";

        // Adjust startHour for 12-hour format
        let adjustedStartHour =
          isPM && startHour === 12
            ? startHour
            : isPM
            ? startHour + 12
            : !isPM && startHour === 12
            ? startHour + 12
            : startHour;
        const isNightTime = adjustedStartHour >= 18 || adjustedStartHour < 5;
        const hourlyPrice = isNightTime ? night : day;

        totalPrice += hourlyPrice;
      });
    });
  }
  return totalPrice;
};

const calculatePerPrice = (
  startTime: string,
  futsalData: Futsal | null
): number => {
  let finalPrice = 0;

  if (futsalData && futsalData.pricePerHour) {
    const { day, night } = futsalData.pricePerHour;

    const startHour = parseInt(startTime.split(" ")[0], 10);
    const isPM = startTime.split(" ")[1].toUpperCase() === "PM";

    let adjustedStartHour =
      isPM && startHour === 12
        ? startHour
        : isPM
        ? startHour + 12
        : !isPM && startHour === 12
        ? startHour + 12
        : startHour;

    const isNightTime = adjustedStartHour >= 18 || adjustedStartHour < 5;

    const hourlyPrice = isNightTime ? night : day;

    finalPrice = hourlyPrice;
  }

  return finalPrice;
};

const handleButtonClickTime = (
  startTime: string,
  availability: any,
  selectedButton: string[],
  setSelectedButton: React.Dispatch<React.SetStateAction<string[]>>,
  method:string,
  user:User
) => {
  const slotData = availability[startTime];

  if (method==="OneTimeBooking"){
    if (slotData.type === "hold") {
      return;
    }
  }

  if (method==="PermanentBooking"){
    if (slotData.type === "hold" && slotData.email !== user.email) {
      return;
    }
  }


  const start12Hour = moment(startTime, "HH:mm").format("hh:mm A");
  const end12Hour = moment(slotData.endTime, "HH:mm").format("hh:mm A");

  const selectedSlot = `${start12Hour} - ${end12Hour}`;

  const isSelected = selectedButton.includes(selectedSlot);

  let updatedSelection;

  if (isSelected) {
    updatedSelection = selectedButton.filter((slot) => slot !== selectedSlot);
  } else {
    updatedSelection = [...selectedButton, selectedSlot];
  }

  updatedSelection.sort((a, b) => {
    const timeA = moment(a.split(" - ")[0], "hh:mm A").valueOf();
    const timeB = moment(b.split(" - ")[0], "hh:mm A").valueOf();
    return timeA - timeB;
  });

  setSelectedButton(updatedSelection);
};

const fetchAvailableTimeSlots = async (
  selectedDate: Date,
  clickedCourt: any,
  setAvailability: React.Dispatch<React.SetStateAction<any | null>>,
  futsalData: any,
  bookingData: any
) => {
  console.log("futsal data", futsalData);
  console.log("booking court", bookingData.courtName);

  if (futsalData) {
    try {
      const endPoint = endPoints.GET_TIME_SLOT;
      const response = await axiosInstance.post(endPoint, {
        futsalId: futsalData.futsalId,
        courtName: clickedCourt.courtName,
        playTime: selectedDate,
        futsalStartTime: futsalData.startTime,
        futsalEndTime: futsalData.endTime,
      });

      const availableTimeSlots = response.data.availableTimeSlots || [];
      const holdBookings = response.data.holdBookings || [];

      const mergedSlots: any = {};

      availableTimeSlots.forEach((slot: any) => {
        // Only include time slots that are after the current time
        const currentTime = moment();
        const slotTime = moment.tz(
          `${moment(selectedDate).format("YYYY-MM-DD")} ${slot.startTime}`,
          "YYYY-MM-DD HH:mm",
          "Asia/Colombo"
        );
        if (slotTime.isSameOrAfter(currentTime)) {
          mergedSlots[slot.startTime] = { ...slot, type: "available" };
        }
      });

      // Add hold bookings to the merged object with a type "hold"
      holdBookings.forEach((booking: any) => {
        mergedSlots[booking.startTime] = { ...booking, type: "hold" };
      });

      console.log("Merged timeslots", mergedSlots);

      setAvailability(mergedSlots);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
};

export {
  getStartAndEndTime,
  calculateTotalDuration,
  calculateTotalPrice,
  calculatePerPrice,
  handleButtonClickTime,
  fetchAvailableTimeSlots,
};
