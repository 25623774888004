import React, { useEffect, useMemo, useRef, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const BookingCard=(props:any)=>{

    const [isArrowClicked,setArrowClick]=useState(false)


    const handleArrowClick=()=>{
        setArrowClick(!isArrowClicked)
    }

    return(
        <div style={{display:"flex",flexDirection:"column"}}>
            <div  className={`upcoming-booking-card ${isArrowClicked ? 'expand-upcoming-booking-card' : ''}`} onClick={handleArrowClick}>
                <div className="half-circle left"></div>
                <div className="half-circle right"></div>
                <div className="line"></div>
                <h1>{props.futsalName}</h1>
                <h3>{props.place}</h3>
                <p className="court-heading">Court</p>
                <p className="court">{props.court}</p>
                <p className="booking-id-heading">Booking ID</p>
                <p className="booking-id">{props.bookingId}</p>
                <p className="date-heading">Date</p>
                <p className="date">{props.date}</p>
                <p className="time-heading">Time</p>
                <p className="time">{props.time}</p>
                {isArrowClicked?<MdKeyboardArrowUp/>:  <MdKeyboardArrowDown/> }
            </div>
            <div className={`${isArrowClicked ? 'expand-card' : 'closed-card'}`}>
                   <button className="booking-cancel-btn">Cancel Booking</button>
            </div>
        </div>
    )
}

export default BookingCard;