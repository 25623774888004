import { ADD_USER } from "../actionTypes/actionTypes";
import {User} from "../interfaces/User"
const addUser = (user:User) => {
    return {
        type: ADD_USER,
        payload:user
    };
};

// const deleteItem = () => {
//     return {
//         type: DELETE_ITEM,
//     };
// };

export { addUser };