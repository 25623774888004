import React from "react";
import Skeleton from "react-loading-skeleton";
import "../skeletonCss/PaymentCardSkeletonCss.css";
import "react-loading-skeleton/dist/skeleton.css";

const PaymentCardSkeleton = () => {
  return (
    <>
      <div className="paymentCardSkeleton-container">
        <div className="paymentCardSkeleton-details-container">
          <div className="paymentCardSkeleton-name-container">
            <Skeleton
              className="paymentCard-name-skeleton"
              width={window.innerWidth >= 1024 ? 300 : 150}
              height={window.innerWidth >= 1024 ? 30 : 30}
            />
          </div>
          <div className="paymentCardSkeleton-address-container">
            <Skeleton
              className="paymentCard-address-skeleton"
              width={window.innerWidth >= 1024 ? 300 : 150}
              height={window.innerWidth >= 1024 ? 30 : 30}
            />
          </div>
        </div>
        <div className="paymentCardSkeleton-booking-container">
          <div className="paymentCardSkeleton-time-container">
            <Skeleton
              className="paymentCard-time-skeleton"
              width={window.innerWidth >= 1024 ? 358 : 264}
              height={window.innerWidth >= 1024 ? 30 : 30}
            />
          </div>
          <div className="paymentCardSkeleton-court-container">
            <Skeleton
              className="paymentCard-court-skeleton"
              width={window.innerWidth >= 1024 ? 358 : 264}
              height={window.innerWidth >= 1024 ? 30 : 30}
            />
          </div>
        </div>


        <div className="paymentCardSkeleton-pay-container">
          <div className="paymentCardSkeleton-total-container">
            <Skeleton
              className="paymentCard-total-skeleton"
              width={window.innerWidth >= 1024 ? 150 : 100}
              height={window.innerWidth >= 1024 ? 30 : 30}
            />
          </div>
          <div className="paymentCardSkeleton-value-container">
            <Skeleton
              className="paymentCard-value-skeleton"
              width={window.innerWidth >= 1024 ? 150 : 100}
              height={window.innerWidth >= 1024 ? 30 : 30}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCardSkeleton;
