import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-toastify/dist/ReactToastify.css';
import store from "./reducers/store";
import { initializeGoogleAnalytics } from "./tracking/GoogleAnalytics";
import Home from './components/home/Home/Home';
import OneTimeBooking from './components/Checkout/OneTimeBooking';
import BookingType from './components/BookingType';
import TokensPage from './components/GUCard/TokensPage';
import PermanentBooking from "./components/Checkout/PermanentBooking";
import ViewAllFutsals from './components/home/ViewAllFutsals';
import Login from "./components/user/Login";
import SignUp from "./components/user/SignUp";
import MyBookings from './components/user/MyBookings';
import Courts from "./components/court/Court/Courts";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import EditProfile from "./components/user/EditProfile";

function App() {
    const [gaKey, setGaKey] = useState<string | null>(null);

    useEffect(() => {
        const gaKey = process.env.REACT_APP_GA4;
        if (gaKey) {
            setGaKey(gaKey);
            initializeGoogleAnalytics(gaKey);
        }
    }, []);

    return (
        <Provider store={store}>
            <div className="App">
                <SkeletonTheme baseColor="#cacaca" highlightColor="#EBEBEB">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/courts-section/:futsalId" element={<Courts />} />
                        <Route path="/oneTimeBooking/:futsalId" element={<OneTimeBooking />} />
                        <Route path="/bookingType/:futsalId" element={<BookingType />} />
                        <Route path="/permanentBooking/:futsalId" element={<PermanentBooking />} />
                        <Route path="/viewAllFutsals" element={<ViewAllFutsals />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/tokens" element={<TokensPage />} />
                        <Route path="/myBookings" element={<MyBookings />} />
                        <Route path="*" element={<PageNotFound />} />
                        <Route path="/edit-profile" element={<EditProfile />} />
                    </Routes>
                </SkeletonTheme>
            </div>
        </Provider>
    );
}

export default App;
