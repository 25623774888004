import {useDispatch, useSelector} from "react-redux";
import {addFutsalId} from "../../actions/FutsalIdAction";
import {RootState} from "../store";

export function useFutsalId():[Number, (futsalId:Number) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, Number>(state => state.futsalId),
        futsalId => dispatch(addFutsalId(futsalId))
    ]
}