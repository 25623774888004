import React, { useState } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { Policy } from "../policy/Policy";

function Footer() {
  const fbLink = "https://www.facebook.com/profile.php?id=61550208122459";
  const instaLink = "https://www.instagram.com/gearup.lk";
  const mailtoLink = `mailto:gearup.omni@gmail.com}`;

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState<React.ReactNode>("");

  const openPopup = (content: React.ReactNode) => {
    setPopupContent(content);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
    setPopupContent("");
  };

  const privacyContent = (
    <div>
      <h2>Cancellation and Refund Policies</h2>
      <p>Last Updated:5/31/2024</p>
      <p>
        At GearUp, we aim to provide a seamless experience for both users and
        court owners. Our cancellation and refund policies are designed to be
        fair and straightforward. Please read them carefully before making a
        booking. <br /> <br />
        <b>1. User Cancellations</b>
        <ul>
          <li>
            {" "}
            Cancellation Window: Users can cancel their booking up to 24 hours
            before the scheduled booking order time to receive a full refund via
            GearUp token.
          </li>
          <li>
            {" "}
            Late Cancellations: Cancellations made within 24 hours of the
            scheduled start time will not be eligible for a refund.
          </li>
          <li>
            {" "}
            How to Cancel: To cancel a booking, users must log in to their
            GearUp account, navigate to their bookings, and select the cancel
            option.
          </li>
        </ul>
        <br />
        <b>2. Court Owner Cancellations</b>
        <ul>
          <li>
            {" "}
            Notification Requirement: Court owners must notify users of any
            cancellations at least 24 hours before the Placed Booking time.
          </li>
          <li>
            {" "}
            User Refund: Users will receive a full refund (token) for any
            booking canceled by the court owner, regardless of the timing.
          </li>
          <li>
            {" "}
            Compensation for Late Cancellations: If a court owner cancels a
            booking within 24 hours of the scheduled start time, the user will
            receive a full refund (token) and a 10% discount on their next
            booking as compensation for the inconvenience.
          </li>
        </ul>
        <br />
        <b>3. No-Shows</b>
        <ul>
          <li>
            {" "}
            User No-Shows: If a user does not show up for their booking and has
            not canceled within the cancellation window, no refund will be
            issued.
          </li>
          <li>
            Court Owner No-Shows: If a court owner fails to honor a booking
            without prior notification, the user will receive a full refund
            (Token) and a 0% discount on their next booking.
          </li>
        </ul>
        <br />
        <b>4. Refund Process</b>
        <ul>
          <li>
            {" "}
            Refund Method: Refunds will be issued to the original payment method
            used for the booking.
          </li>
          <li>
            {" "}
            Refund Timeframe: Refunds will be processed within 5-7 business days
            from the date of cancellation.
          </li>
        </ul>
        <br />
        <b>5. Exceptional Circumstances</b>
        <ul>
          <li>
            {" "}
            Weather-Related Cancellations: In case of severe weather or other
            natural events that prevent the booking from taking place, users
            will receive a full refund or the option to reschedule at no
            additional cost.
          </li>
          <li>
            {" "}
            Health and Safety: If a booking is canceled due to health and safety
            concerns, including public health emergencies, users will receive a
            full refund.
          </li>
        </ul>
        <br />
        <b>6. Dispute Resolution</b>
        <ul>
          <li>
            {" "}
            Contact Us: If there is a dispute regarding a cancellation or
            refund, users and court owners can contact our support team at
            support@gearup.com for assistance.
          </li>
          <li>
            Resolution Process: Our team will review the case and aim to resolve
            the issue within 7 business days.
          </li>
        </ul>
        <br />
        <b>7. Changes to Policies</b>
        <ul>
          <li>
            {" "}
            Policy Updates: GearUp reserves the right to update these
            cancellation and refund policies at any time. Users and court owners
            will be notified of any changes, and continued use of the platform
            constitutes acceptance of the updated policies.
          </li>
        </ul>
        <br />
        By using the GearUp platform, you agree to these cancellation and refund
        policies. If you have any questions or concerns, please contact us at
        support@gearup.com. .
      </p>
    </div>
  );

  const termsContent = (
    <div>
      <h2>Terms and Conditions</h2>
      <p>Last Updated: 5/31/2024</p>
      <p>
        Welcome to GearUp! These terms and conditions outline the rules and
        regulations for the use of our online booking platform, located at{" "}
        <a href="https://gearup.lk/">www.gearup.com</a>. By accessing this
        website, we assume you accept these terms and conditions in full. Do not
        continue to use GearUp’s website if you do not accept all of the terms
        and conditions stated on this page. <br /> <br />
        <b>1. Definitions</b>
        <ul>
          <li>
            "User": Refers to any individual who uses our platform to view,
            book, or play futsal courts.
          </li>
          <li>
            "Court Owner": Refers to any individual or entity that registers
            their futsal court on our platform.
          </li>
          <li>"Platform": Refers to the GearUp website and its services.</li>
        </ul>
        <br />
        <b>2. User Responsibilities</b>
        <ul>
          <li>
            Users must provide accurate and complete information when creating
            an account.
          </li>
          <li>
            {" "}
            Users are responsible for maintaining the confidentiality of their
            account information and are fully responsible for all activities
            that occur under their account.
          </li>
        </ul>
        <br />
        <b>3. Booking and Payments</b>
        <ul>
          <li>
            {" "}
            Users can book futsal courts through our platform. All bookings are
            subject to availability.
          </li>
          <li>
            Payment for bookings must be made through the payment methods
            provided on the platform.
          </li>
          <li>
            {" "}
            Cancellation and refund policies are outlined in our Booking Policy,
            which users must read and agree to before booking.
          </li>
        </ul>
        <br />
        <b>4. Court Owner Responsibilities</b>
        <ul>
          <li>
            {" "}
            Court Owners must provide accurate and complete information about
            their futsal courts, including availability, pricing, and amenities.
          </li>
          <li>
            {" "}
            Court Owners are responsible for maintaining the quality and safety
            of their courts.
          </li>
          <li>
            {" "}
            Court Owners agree to adhere to all local laws and regulations
            related to the operation of their futsal courts.
          </li>
        </ul>
        <br />
        <b>5. Content Ownership and Use</b>
        <ul>
          <li>
            {" "}
            All content provided on the GearUp platform, including text,
            graphics, logos, and images, is the property of GearUp or its
            content suppliers and is protected by intellectual property laws.
          </li>
          <li>
            {" "}
            Users and Court Owners may not use any content from the platform for
            commercial purposes without obtaining prior written consent from
            GearUp.
          </li>
        </ul>
        <br />
        <b>6. Limitation of Liability</b>
        <ul>
          <li>
            GearUp is not liable for any direct, indirect, incidental, or
            consequential damages arising from the use or inability to use our
            platform.
          </li>
          <li>
            {" "}
            GearUp does not guarantee the accuracy, completeness, or reliability
            of any information provided by Court Owners.
          </li>
        </ul>
        <br />
        <b>7. Indemnification</b>
        <ul>
          <li>
            Users and Court Owners agree to indemnify, defend, and hold harmless
            GearUp, its affiliates, and their respective officers, directors,
            employees, and agents from any claims, liabilities, damages, and
            expenses (including legal fees) arising from their use of the
            platform or breach of these terms and conditions.
          </li>
        </ul>
        <br />
        <b>8. Modifications to Terms</b>
        <ul>
          <li>
            {" "}
            GearUp reserves the right to modify these terms and conditions at
            any time. Users and Court Owners will be notified of any changes,
            and continued use of the platform constitutes acceptance of the
            modified terms.
          </li>
        </ul>
        <br />
        <b>9. Governing Law</b>
        <ul>
          <li>
            {" "}
            These terms and conditions are governed by and construed in
            accordance with the laws of Sri Lanka, and any disputes will be
            subject to the exclusive jurisdiction of the courts of Sri Lanka.
          </li>
        </ul>
        <br />
        <b>10. Contact Information</b>
        <ul>
          <li>
            {" "}
            For any questions or concerns regarding these terms and conditions,
            please contact us at support@gearup.com.
          </li>
        </ul>
      </p>
    </div>
  );

  const handleSubmit = () => {};
  return (
    <>
      <div className="footer-container">
        <div className="border-glow">
          <div className="footer-links-all">
            <div className="footer-links">
              <h4>Company</h4>
              <a href="/my-app/public">About Us</a>
              <a href="/my-app/public">My Account</a>
              <a href="/my-app/public">FAQs</a>
              <a href="/my-app/public">Teams</a>
              <a href="/my-app/public">Contact</a>
            </div>
            <div className="footer-social">
              <h4>Follow Us</h4>
              <a href={fbLink} target="_blank">
                <FaFacebookF />
                <span>Facebook</span>
              </a>
              <a href={instaLink} target="_blank">
                <FaInstagram />
                <span>Instagram</span>
              </a>
              <a href={mailtoLink} target="_blank">
                <SiGmail />
                <span>Gmail</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        &#169; 2023 omnitrix - All rights reserved
        <div className="footer-copyright-links">
          <button onClick={() => openPopup(privacyContent)}>
            Privacy Policy
          </button>
          <button onClick={() => openPopup(termsContent)}>
            Terms and Conditions
          </button>
          <a href={fbLink}>Cookies Settings</a>
        </div>
      </div>
      <Policy
        isOpen={isPopupOpen}
        onClose={closePopup}
        content={popupContent}
      />
    </>
  );
}

export default Footer;
