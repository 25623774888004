import React, {useEffect, useState} from 'react';
import Logo from '../../asserts/GearupLogo-black.svg';
import {User} from "../../interfaces/User";
import {axiosInstance} from "../../utils/axiosInstance";
import {endPoints} from "../../utils/endPoints";
import {useCookies} from "react-cookie";
import {toast} from "react-toastify";

function CourtNavigation() {

    const [userDetails, setUserDetails] = useState<User>();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const[userEmail,setUserEmail]=useState();


    useEffect(() => {
        const verifyCookie = async () => {
            const token=localStorage.getItem("token")
            const { data } = await axiosInstance.post(
                endPoints.USER_AUTHENTICATION,
                {token},
                { withCredentials: true }
            );
            const { status, user ,name} = data;
            setUserEmail(user)
            return status
                ?""
                : localStorage.removeItem("token");
        };
        verifyCookie();
    }, [cookies, removeCookie]);

    useEffect(() => {
        if (userEmail) {
            axiosInstance.get(`${endPoints.GET_USER}${userEmail}`)
                .then(response => {
                    if (response.data.status === 'ok') {
                        setUserDetails(response.data.user);
                    }
                })
                .catch(error => {
                    console.error('Error fetching user details:', error);
                });
        }
    }, [userEmail]);

    const handleLogoClick = () => {
        window.location.href = '/';
    };

    return (
        <nav>
            <div className="courts-nav-container">
                <div className="courts-logo" onClick={handleLogoClick}>
                    <img src={Logo} alt="Gear-up-logo" />
                </div>
                {!userDetails && <div className="sign-in-buttons">
                    <a href="/login" className="sign-in-btn">
                        LOGIN
                    </a>
                    <a href="/signup" className="sign-up-btn">
                        SIGN UP
                    </a>
                </div>}
            </div>
        </nav>
    );
}

export default CourtNavigation;
