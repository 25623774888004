import { ADD_USER } from "../actionTypes/actionTypes";
import {User} from "../interfaces/User"

interface UserAction {
    type: String;
    payload: User;
}

const initialState: User ={
    name: "" ,
    email: "",
    phoneNumber: "",
    dateOfBirth:null,
    district:"",
    picture:"",
    gender:"",
    passwordHash:""
};

const userReducer = (state = initialState, action: UserAction): User => {
    switch (action.type) {
        case ADD_USER:
            return action.payload;
        default:
            return state;
    }
};

export default userReducer;
