import React, { useEffect, useState } from "react";
import BookingCard from "../interfaces/cards/BookingCard";
import Carousel from "react-multi-carousel";
import MyBookingCard from "../interfaces/cards/MyBookingCard";
import moment from "moment";
import BookingCardSkeleton from "../skeletonLoaders/BookingCardSkeleton";
import {endPoints} from "../../utils/endPoints";
import {axiosInstance} from "../../utils/axiosInstance";
import { useCookies } from "react-cookie";
import { User } from "../../interfaces/User";
import { toast } from "react-toastify";
import "react-multi-carousel/lib/styles.css";

export interface Booking {
    bookingId: string;
    userName: string;
    email:string;
    futsalId:number;
    bookingTime:Date|null;
    playStartTime: Date|null;
    playEndTime: Date|null;
    startTime: string;
    endTime: string;
    courtName: string;
    price:number;
    status:String;
    futsalName:string;
    place:string
  
  }
const UpcomingBookings: React.FC = () => {

    const [isLoaded, setLoaded] = useState(false);
    const currentDate = new Date();
    const [bookings,setBookings]=useState<Booking[]>([])
    const [loading, setLoading] = useState(true);
    const [userEmail, setUserEmail] = useState();
    const [activeTab, setActiveTab] = useState(1);
    const [filterOption, setFilterOption] = useState('All')
    const [showDropdown, setShowDropdown] = useState(false)
    const [cookies, , removeCookie] = useCookies(["token"]);
    const [userDetails, setUserDetails] = useState<User>();
    
  

    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 4
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
      };

      useEffect(() => {
        const verifyCookie = async () => {
          const token=localStorage.getItem("token")
          const { data } = await axiosInstance.post(
              endPoints.USER_AUTHENTICATION,
              {token},
              { withCredentials: true }
          );
          const { status, user, name } = data;
          setUserEmail(user)
          return status
              ? ""
              : localStorage.removeItem("token");
        };
        verifyCookie();
      }, [cookies, removeCookie]);

      
      useEffect(() => {
        if (userEmail) {
          axiosInstance.get(`${endPoints.GET_USER}${userEmail}`)
              .then(response => {
                if (response.data.status === 'ok') {
                  setUserDetails(response.data.user);
                  setLoading(false);
                }
              })
              .catch(error => {
                console.error('Error fetching user details:', error);
                setLoading(false);
              });
        }
      }, [userEmail]);

      useEffect(() => {
        if (userEmail) {
          axiosInstance.get(`${endPoints.GET_USER_BOOKINGS}${userEmail}`)
              .then(async response => {
                if (response.data.status === 'ok') {
                  const fetchedBookings = response.data.allBookings;
                  const updatedBookings = [];
    
                  // Iterate through each booking to fetch futsal details
                  for (const booking of fetchedBookings) {
                    try {
                      const futsalResponse = await axiosInstance.get(`${endPoints.GET_FUTSAL}${booking.futsalId}`);
                      if (futsalResponse.data.status === 'ok') {
                        const updatedBooking = {
                          ...booking,
                          futsalName: futsalResponse.data.futsal.name,
                          place: futsalResponse.data.futsal.place
                        };
                        updatedBookings.push(updatedBooking);
                      }
                    } catch (error) {
                      console.error('Error fetching futsal details:', error);
                    }
                  }
    
                  // Update the state with the updated bookings
                  setBookings(updatedBookings);
                  setLoading(false);
                  setLoaded(true); // Set isLoaded to true once bookings are fetched and processed
                }
              })
              .catch(error => {
                console.error('Error fetching bookings details:', error);
                setLoading(false);
              });
        }
      }, [userEmail]);

      
      const bookingCard = (booking: any, index: any) => (
        <BookingCard
          key={index}
          futsalName={booking.futsalName}
          place={booking.place}
          court={booking.courtName}
          bookingId={booking.bookingId}
          date={new Date(booking.playStartTime).toLocaleDateString()}
          time={`${moment(booking.startTime, "HH:mm").format("hh:mm A")}-${moment(booking.endTime, "HH:mm").format("hh:mm A")}`}
        />
      );
      
    
        const futureBookings = bookings.filter((booking) => {
            const bookingDate = new Date(booking.playStartTime!);
            bookingDate.setUTCHours(bookingDate.getUTCHours() - 5);
            bookingDate.setUTCMinutes(bookingDate.getUTCMinutes() - 30);

            return (
                bookingDate > currentDate ||
                (bookingDate.getDate() === currentDate.getDate() &&
                    bookingDate.getHours() > currentDate.getHours())
            );
        });


    return (
        (userEmail && futureBookings.length>0) ? (
        <div className="upcoming-bookings">
        <h1>Upcoming Bookings</h1>
        {!isLoaded ? (
          <Carousel
              arrows={true} 
              responsive={responsive}
              showDots={false}
              swipeable={true}
              infinite={false}
              autoPlay={true}
              autoPlaySpeed={3750}
          >
              {[...Array(4)].map((_, index) => {
                  return <BookingCardSkeleton key={index} />;
              })}
          </Carousel>
          ) : futureBookings.length > 0 && (
              <Carousel
                  arrows={true}
                  responsive={responsive}
                  showDots={false}
                  swipeable={true}
                  infinite={false}
                  autoPlay={true}
                  autoPlaySpeed={3750}
              >

            {futureBookings
                .sort((a, b) => new Date(a.playStartTime!).getTime() - new Date(b.playStartTime!).getTime())
                .map((booking, index) => bookingCard(booking, index))}


              </Carousel>
          )
        }
    </div>
       ) : null
 
    );
};

export default UpcomingBookings;
