import {Routes, Route, useNavigate, createSearchParams} from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import futsal1 from '../../../asserts/futsal-1.jpg';
import futsal2 from '../../../asserts/futsal-2.jpeg';
import futsal3 from '../../../asserts/futsal-3.jpg'
import { AiFillStar } from 'react-icons/ai'
import {useFutsalId} from "../../../reducers/ui/FutsalIdHook";
import {useEffect, useState} from "react";
import {axiosInstance} from "../../../utils/axiosInstance";
import {endPoints} from "../../../utils/endPoints";



const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

 interface  Review{
  futsalId: number;
  ratingPoint: number;
  message: String;
  customerName: String;
}

function FutsalCard(props: any) {
  const navigate = useNavigate();
  let [futsalId,setFutsalId]=useFutsalId();
  const [review,setReview]=useState<Review[]>([]);


    useEffect(() => {

    axiosInstance.get(`${endPoints.GET_REVIEW}${props.futsalId}`)
        .then(response => {
          console.log(response.data.allRating)
          setReview(response.data.allRating)
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

  }, [props.futsalId])

  const calculateAverageRating = (): number => {
    if (review.length === 0) return 0;

    const totalRating = review.reduce(
        (accumulator, currentReview) => accumulator + currentReview.ratingPoint,
        0
    );
    return totalRating / review.length;
  };


  // function navigateToFutsalPage(futsalId:number){
  //   console.log(futsalId)
  //   setFutsalId(futsalId)
  //   navigate({
  //     pathname: "/courts-section",
  //     search: createSearchParams({
  //       futsalId: props.futsalId,
  //     }).toString()
  //   })
  //
  // }
    function navigateToFutsalPage(futsalId:number) {
        console.log(futsalId);
        setFutsalId(futsalId);
        navigate(`/courts-section/${futsalId}`);
    }


  return (
      <div className="futsal-card-stroke">
          <div className="futsal-card-container" onClick={() => navigateToFutsalPage(props.futsalId)}>
              <Carousel
                  arrows={false}
                  responsive={responsive}
                  showDots={false}
                  swipeable={false}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={5750}
              >
                  <img src={futsal1} alt="futsal-image-1"/>
                  <img src={futsal2} alt="futsal-image-2"/>
                  <img src={futsal3} alt="futsal-image-3"/>
              </Carousel>
              <div className="futsal-body">
                  <h2 className="first-word-futsal-name">{props.name.split(" ")[0].toUpperCase()}</h2>
                  <h2 className="second-word-futsal-name">{props.name.split(" ")[1].toUpperCase()} </h2>
                  <hr className="line1"/>
                  <div className="futsal-card-end">
                      <div className="first-box">
                          <p className="rating">{calculateAverageRating().toFixed(1)} <AiFillStar/> &nbsp;
                              <span>({review.length})</span></p>
                          <b className="place-p">{props.place}</b>
                      </div>
                      <div className="price-button">
                          <h4>Rs.{props.price}/<span>hr</span></h4>

                      </div>
                  </div>
              </div>
          </div>

      </div>
  );
}

export default FutsalCard;
