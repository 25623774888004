import React from 'react'

function MyBookingCard(props:any) {
  return (
    <div style={{display:"flex",flexDirection:"column"}}>
            <div  className={`my-booking-card`}>
                <div className="half-circle left"></div>
                <div className="half-circle right"></div>
                <div className="line"></div>
                <h1>{props.futsalName}</h1>
                <h3>{props.place}</h3>
                <p className="court-heading">Court</p>
                <p className="court">{props.court}</p>
                <p className="booking-id-heading">Booking ID</p>
                <p className="booking-id">{props.bookingId}</p>
                <p className="date-heading">Date</p>
                <p className="date">{props.date}</p>
                <p className="time-heading">Time</p>
                <p className="time">{props.time}</p>
            </div>
           
        </div>
  )
}

export default MyBookingCard