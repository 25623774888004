import React, { useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FutsalCard from "../interfaces/cards/FutsalCard";
import CourtSkeleton from "../skeletonLoaders/CourtSkeleton";
import { Futsal } from "../../interfaces/Futsal";
import NoFutsal from "../../asserts/No-futsal.png";

interface FeaturedPlacesToPlayProps {
    futsals: Futsal[];
    selectedLocation: String;
    isLoaded: boolean;
    futsalContainerRef: React.RefObject<HTMLDivElement>;
    handleLocationClick: (location: string) => void;
    navigateToViewAll: () => void;
    getLocationButtonClass: (location: string) => string;
    responsive: any; // Adjust the type according to your actual responsive object
}

const FeaturedPlacesToPlay: React.FC<FeaturedPlacesToPlayProps> = ({
                                                                       futsals,
                                                                       selectedLocation,
                                                                       isLoaded,
                                                                       futsalContainerRef,
                                                                       handleLocationClick,
                                                                       navigateToViewAll,
                                                                       getLocationButtonClass,
                                                                       responsive,
                                                                   }) => {
    const [time] = useState(new Date());

    return (
        <div className="futsal-container" ref={futsalContainerRef}>
            <h1>Featured Places To Play</h1>
            <p className="futsal-head-p">
                Popular Places To Book Your Court That's Recommends For You
            </p>
            <div className="sorting-buttons">
                <div className="location-buttons">
                    <button
                        className={getLocationButtonClass("Colombo")}
                        onClick={() => handleLocationClick("Colombo")}
                    >
                        Colombo
                    </button>
                    <button
                        className={getLocationButtonClass("Kandy")}
                        onClick={() => handleLocationClick("Kandy")}
                    >
                        Kandy
                    </button>
                    <button
                        className={getLocationButtonClass("Galle")}
                        onClick={() => handleLocationClick("Galle")}
                    >
                        Galle
                    </button>
                </div>
                <button className="viewAll-button" onClick={navigateToViewAll}>
                    View All <AiOutlineArrowDown size={20} />
                </button>
            </div>
            {isLoaded ? (
                <Carousel
                    arrows={false}
                    responsive={responsive}
                    showDots={true}
                    swipeable={true}
                    infinite={false}
                    autoPlay={true}
                    autoPlaySpeed={8750}
                >
                    {[...Array(9)].map((_, index) => {
                        return <CourtSkeleton key={index} />;
                    })}
                </Carousel>
            ) : futsals.filter((courts) => courts.district === selectedLocation).length >
            0 ? (
                <Carousel
                    arrows={false}
                    responsive={responsive}
                    showDots={true}
                    swipeable={true}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3750}
                >
                    {futsals
                        .filter(
                            (court) =>
                                court.district === selectedLocation && court.available
                        )
                        .map((court, index) => {
                            const currentTime = time.getHours(); // Get current hour (0-23)
                            const isDayTime = currentTime < 18; // Check if it's before 18:00
                            const price = isDayTime
                                ? court.pricePerHour.day
                                : court.pricePerHour.night;

                            return (
                                <FutsalCard
                                    key={index}
                                    name={court.name}
                                    numberOfCourts={court.numberOfCourt}
                                    place={court.place}
                                    price={price}
                                    futsalId={court.futsalId}
                                />
                            );
                        })}
                </Carousel>
            ) : (
                <div className="no-futsal-container">
                    <img src={NoFutsal}/>
                </div>
            )}
        </div>
    );
};

export default FeaturedPlacesToPlay;
