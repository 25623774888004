import React from 'react';
import {Futsal} from "../../../../interfaces/Futsal";
import PaymentCardSkeleton from "../../../skeletonLoaders/PaymentCardSkeleton";


interface Court {
    _id: string;
    courtName: string;
}
interface Props {
    selectedDate:Date |null;
    futsalData: Futsal | null | undefined;
    selectedDateFormatted: string |null;
    selectedButton: string[];
    selectCourts: Court[];
    calculateTotalDuration: (selectedButton: string[]) => string;
    calculatePerPrice: (startTime: string, futsalData: Futsal) => number;
    calculateTotalPrice: (futsalData: Futsal, selectCourts: Court[], selectedButton: string[]) => number;
    handleNextBtn: () => void;
}
const BookingSummary :  React.FC<Props> = ({
                            selectedDate,
                            futsalData,
                            selectedDateFormatted,
                            selectedButton,
                            selectCourts,
                            calculateTotalDuration,
                            calculatePerPrice,
                            calculateTotalPrice,
                            handleNextBtn
                        }) => {
    return (
        <div className="date-time-booking-1">
            <h3 className="your-booking">Your Booking</h3>
            {futsalData ? <div className="payment-container-border">
                <div className="payment-container">
                    {futsalData && (
                        <div>
                            <h3 style={{textAlign: "center"}}>{futsalData.name}</h3>
                            <p className="address">
                                {futsalData.address} {futsalData.place}
                            </p>
                            <h4>{selectedDateFormatted}</h4>
                            <p>Time Duration: {calculateTotalDuration(selectedButton)}</p>
                        </div>
                    )}
                    <hr/>

                    {selectCourts.map((court) => (
                        <div key={court._id} className="booked-courts-1">
                            <h4>Court: {court.courtName}</h4>
                            {selectedButton.map((startTime, index) => (
                                <div key={index} className="booked-courts-1-time">
                                    <p>{startTime}</p>
                                    {futsalData && (
                                        <div className="booked-price">
                                            <p>Rs.{calculatePerPrice(startTime, futsalData)}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                    <hr/>
                    {futsalData && (
                        <div className="booked-total">
                            <h4>Total</h4>
                            <h4>
                                Rs.
                                {calculateTotalPrice(
                                    futsalData,
                                    selectCourts,
                                    selectedButton
                                )}
                            </h4>
                        </div>
                    )}
                </div>
            </div>  : <PaymentCardSkeleton/>}

            {selectedDate && selectedButton.length > 0 && (
                <div className="next-btn" onClick={handleNextBtn}>
                    <button>Next</button>
                </div>
            )}
        </div>
    );
}

export default BookingSummary;
