import LogoWhite from "../../asserts/Gearup logo Beta.png";
import Profile from "../../asserts/profile.jpg";
import React, { useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../utils/axiosInstance";
import { endPoints } from "../../utils/endPoints";
import { User } from "../../interfaces/User";
import { GrEdit } from "react-icons/gr";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import {sendGoogleAnalyticsEvent} from "../../tracking/GoogleAnalytics";
import {events} from "../../tracking/events/events";

const Navigation = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [userDetails, setUserDetails] = useState<User>();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [userEmail, setUserEmail] = useState();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const verifyCookie = async () => {
      const token=localStorage.getItem("token")
      const { data } = await axiosInstance.post(
        endPoints.USER_AUTHENTICATION,
        {token},
        { withCredentials: true }
      );
      const { status, user, name } = data;
      setUserEmail(user);
      return status ? "" : localStorage.removeItem("token");
    };
    verifyCookie();
  }, [cookies, removeCookie]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (userEmail) {
      axiosInstance
        .get(`${endPoints.GET_USER}${userEmail}`)
        .then((response) => {
          if (response.data.status === "ok") {
            setUserDetails(response.data.user);
          }
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        });
    }
  }, [userEmail]);

  const handleLogout = async () => {
    // try {
    //   const response = await axiosInstance.post(
    //     endPoints.LOGOUT,
    //     {},
    //     { withCredentials: true }
    //   );
    //   if (response.status === 200) {
    //     console.log("Logout successful");
    //     window.location.reload();
    //   } else {
    //     console.error("Logout failed");
    //   }
    // } catch (error) {
    //   console.error("Error during logout:", error);
    // }
    localStorage.removeItem("token")
    window.location.reload();
  };

  const toggleDropdown = () => {
    sendGoogleAnalyticsEvent(events.INPUT_CLICK, {
      clicked: `profile button clicked`,
    });
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <nav>
      <div className="nav-container">
        <div className="logo" onClick={() => (window.location.href = "/")}>
          <img src={LogoWhite} alt="Gear-up-logo" />
        </div>
        {userEmail ? (
          <div className="nav-btns">
            <div className="profile-dropdown" ref={dropdownRef}>
              <button className="arrow-btn" onClick={toggleDropdown}>
                <img src={userDetails?.picture?.toString() || Profile} alt="" />
              </button>
              {dropdownVisible && (
                <div className="profile-dropdown-content">
                  <div className="dropdown-edit-profile">
                    <div className="profile-and-name">
                      <img
                        src={userDetails?.picture?.toString() || Profile}
                        alt=""
                      />
                      <p>{userDetails?.name?.split(" ")[0]}</p>
                    </div>
                    <a href="/edit-profile">
                      <GrEdit />
                    </a>
                  </div>
                  <a href="/myBookings">
                    <span>● &nbsp;</span> My Bookings
                  </a>

                  {/*<a href="/tokens">*/}
                  {/*  <span style={{ color: "green" }}>● &nbsp;</span> GU Card*/}
                  {/*</a>*/}

                  {/*<a href="/login">*/}
                  {/*  <span>● &nbsp;</span> Login*/}
                  {/*</a>*/}

                  <button onClick={handleLogout}>
                    <span>● &nbsp;</span> Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="sign-in-buttons">
            <a href="/login" className="sign-in-btn">
              LOGIN
            </a>
            <a href="/signup" className="sign-up-btn">
              SIGN UP
            </a>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </nav>
  );
};

export default Navigation;
