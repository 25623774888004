import React from "react";
import Skeleton from "react-loading-skeleton";
import "../skeletonCss/BookingTypeSkeleton.css";
import 'react-loading-skeleton/dist/skeleton.css';

const BookingTypeSkeleton = () => {
  return (
    <div className="bookingTypeSkeleton-container">
        <div className="bookingTypeSkeleton-name-container">
          <Skeleton className="bookingType-name-skeleton" width={window.innerWidth >= 1024 ? 300: 150} height={window.innerWidth >= 1024 ? 30 : 30}  />
        </div>
        <div className="bookingTypeSkeleton-address-container">
          <Skeleton className="bookingType-address-skeleton" width={window.innerWidth >= 1024 ? 300: 150}  height={window.innerWidth >= 1024 ? 30 : 30} />
        </div>
     </div>
  );
};

export default BookingTypeSkeleton;
