import React from "react";
import Skeleton from "react-loading-skeleton";
import "../skeletonCss/ViewAllCourtSkeleton.css"
import 'react-loading-skeleton/dist/skeleton.css'


const ViewAllCourtSkeleton = () => {
    return (
        <>
            <div className="view-all-card-skeleton" >
                <Skeleton className="view-all-court-image-skeleton" width={window.innerWidth >= 1024 ? 160:80} height={window.innerWidth >= 1024 ? 100:80} />
                <div className="view-all-text-container">
                    <Skeleton className="view-all-court-name-skeleton" width={window.innerWidth >= 1024 ? 90:80} height={window.innerWidth >= 1024 ? 20:10} />
                    <Skeleton className="view-all-court-place-skeleton" width={window.innerWidth >= 1024 ? 90:80} height={window.innerWidth >= 1024 ? 20:10} />
                    <Skeleton className="view-all-court-rating-skeleton" width={window.innerWidth >= 1024 ? 90:80} height={window.innerWidth >= 1024 ? 20:10} />
                </div>
                {/*<Skeleton className="view-all-court-price-skeleton" width={window.innerWidth >= 1024 ? 120:80} height={window.innerWidth >= 1024 ? 20:15} />*/}
            </div>
        </>
    );
};

export default ViewAllCourtSkeleton;
