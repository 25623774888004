import { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../../css/CoverFlow.css';

import { EffectCoverflow, Navigation, Pagination } from 'swiper';

function CoverFlow() {
    useEffect(() => {
        const slides = document.querySelectorAll('.swiper-slide');
        slides.forEach((slide) => {
            slide.addEventListener('mouseenter', () => {
                slide.classList.add('hovered');
            });
            slide.addEventListener('mouseleave', () => {
                slide.classList.remove('hovered');
            });
        });
    }, []);

    return (
        <div className="cover-flow-container">
            <h1 className="learn-gearup-head"> LEARN GEARUP</h1>
            <Swiper
                effect={'coverflow'}
                grabCursor={false}
                centeredSlides={true}
                loop={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2.5,
                }}
                pagination={{el: '.swiper-pagination', clickable: true}}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_container"
            >
                <SwiperSlide>
                    <div className="steps-co">
                        <div className="front-content">
                            <h1 className="steps-first-text">How to make a</h1>
                            <h1 className="steps-second-text">Permanent booking</h1>
                        </div>
                        <div className="back-content">
                            <p>• Navigate to the booking section or page</p>
                            <p>• Select the option for Permanent Booking.</p>
                            <p>• Select the date and time slots for your permanent booking.</p>
                            <p>• Confirm the booking and proceed to payment if required.</p>
                            <p>• Your booking will now be automatically reserved for the selected time slots on the specified days.</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="steps-co">
                        <div className="front-content">
                            <h1 className="steps-first-text">How to use a</h1>
                            <h1 className="steps-second-text">GU card</h1>
                        </div>
                        <div className="back-content">
                            <p>• Access the My Bookings section.</p>
                            <p>• Cancel a booking that you won't be able to attend.</p>
                            <p>• Upon cancellation, you'll receive a GU card ticket as credit.</p>
                            <p>• Next time you make a booking, choose the option to use GU card credit</p>
                            <p>• Apply the GU card credit during the checkout process.</p>
                            <p>• The value of the GU card ticket will be deducted from your total booking cost.</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="steps-co">
                        <div className="front-content">
                            <h1 className="steps-first-text">How to make a</h1>
                            <h1 className="steps-second-text">Permanent booking</h1>
                        </div>
                        <div className="back-content">
                            <p>• Navigate to the booking section or page</p>
                            <p>• Select the option for Permanent Booking.</p>
                            <p>• Select the date and time slots for your permanent booking.</p>
                            <p>• Confirm the booking and proceed to payment if required.</p>
                            <p>• Your booking will now be automatically reserved for the selected time slots on the specified days.</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="steps-co">
                        <div className="front-content">
                            <h1 className="steps-first-text">How to use a</h1>
                            <h1 className="steps-second-text">GU card</h1>
                        </div>
                        <div className="back-content">
                            <p>• Access the My Bookings section.</p>
                            <p>• Cancel a booking that you won't be able to attend.</p>
                            <p>• Upon cancellation, you'll receive a GU card ticket as credit.</p>
                            <p>• Next time you make a booking, choose the option to use GU card credit</p>
                            <p>• Apply the GU card credit during the checkout process.</p>
                            <p>• The value of the GU card ticket will be deducted from your total booking cost.</p>
                        </div>
                    </div>
                </SwiperSlide>
                <div className="slider-controler">
                     <div className="swiper-pagination"></div>
                </div>
            </Swiper>
        </div>
    );
}

export default CoverFlow;

