import { createStore, combineReducers } from "redux";
import futsalReducer from "./futsalReducer";
import userReducer from "./userReducer";
import bookingReducer from "./bookingReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import {Futsal} from "../interfaces/Futsal";
import { User} from "../interfaces/User";
import {Booking} from "../interfaces/Booking";
import {Review} from "../interfaces/Review";
import reviewReducer from "./reviewReducer";
import futsalIdReducer from "./FutsalIdReducer";



export  interface RootState {
    booking: Booking[];
    futsalId:Number;
    futsal: Futsal;
    review: Review[];
    user:User
}


const rootReducer = combineReducers({
    user: userReducer,
    futsal: futsalReducer,
    futsalId:futsalIdReducer,
    review:reviewReducer,
    bookings:bookingReducer
});

const store = createStore(rootReducer, composeWithDevTools());

export default store;
