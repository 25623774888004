import React, { useEffect, useState } from "react";
import futsal1 from "../../../asserts/futsal-1.jpg";
import Footer from "../../footer/Footer";
import { useFutsal } from "../../../reducers/ui/futsalHook";
import { axiosInstance } from "../../../utils/axiosInstance";
import { endPoints } from "../../../utils/endPoints";
import {createSearchParams, useNavigate, useParams} from "react-router-dom";
import { useUser } from "../../../reducers/ui/userHook";
import { Review } from "../../../interfaces/Review";
import CourtNavigation from "../../navigation/CourtNavigation";
import CourtHeader from "../CourtHeader";
import CourtGallery from "../CourtGallery";
import Services from "../Services";
import CourtReview from "../CourtReview";
import CourtAbout from "../CourtAbout";
import CourtNote from "../CourtNote";
import {useCookies} from "react-cookie";
import {toast, ToastContainer} from "react-toastify";
import ReactGA from "react-ga4";

function Courts() {
  const navigate = useNavigate();
  const { futsalId } = useParams();
  const [user, setUser] = useUser();
  const [futsal, setFutsal] = useFutsal();
  const [reviews, setReviews] = useState<Review[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const[userEmail,setUserEmail]=useState();
  const [isAllowed,setIsAllowed]=useState(false)


    useEffect(() => {
        const verifyCookie = async () => {
            const token=localStorage.getItem("token")

            // if (!token) {
            //     toast.error("session expired")
            // }
            const { data } = await axiosInstance.post(
                endPoints.USER_AUTHENTICATION,
                {token},
                { withCredentials: true }
            );
            const { status, user ,name} = data;
            setUserEmail(user)
            return status
                ?""
                :localStorage.removeItem("token");
        };
        verifyCookie();
    }, [cookies, removeCookie]);

    useEffect(() => {
        if (userEmail) {
            axiosInstance.get(`${endPoints.GET_USER}${userEmail}`)
                .then(response => {
                    if (response.data.status === 'ok') {
                        setUser(response.data.user);
                        setIsAllowed(true)
                    }
                })
                .catch(error => {
                    console.error('Error fetching user details:', error);
                });
        }
    }, [userEmail]);

  useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: "/courts-section", title: "Court page" });
      window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    // Fetch futsal data
    axiosInstance
        .get(`${endPoints.GET_FUTSAL}${futsalId}`)
        .then((response) => {
          console.log(response.data.futsal);
          setFutsal(response.data.futsal);
        })
        .catch((error) => {
          console.error("Error fetching futsal data:", error);
        });
  }, [futsalId]);

  useEffect(() => {
    // Fetch reviews data
    if (futsal.futsalId) {
      axiosInstance
          .get(`${endPoints.GET_REVIEW}${futsal.futsalId}`)
          .then((response) => {
            console.log(response.data.allRating);
            setReviews(response.data.allRating);
          })
          .catch((error) => {
            console.error("Error fetching reviews:", error);
          });
    }
  }, [futsal.futsalId]);

    function bookNowHandle() {
        user.name?navigate(`/bookingType/${futsalId}`): toast.info("Please login to continue");
    }

  return (
      <>
        <CourtNavigation />
        <div className="courts-section">
          <div className="courts-section-body">
            <div className="court-details-header">
              <CourtHeader futsal={futsal} bookNowHandle={bookNowHandle} review={reviews} />
              <CourtGallery futsal1={futsal1} />
            </div>
            <Services futsal={futsal} />
            <CourtReview isAllowed={isAllowed} reviews={reviews} futsal={futsal} user={user} setReviews={setReviews} />
            <CourtAbout />
          </div>
          <CourtNote />
        </div>
        <Footer />
        <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
        />
      </>
  );
}

export default Courts;
