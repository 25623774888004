import {useDispatch, useSelector} from "react-redux";
import {addFutsal} from "../../actions/fusalAction";
import {RootState} from "../store";
import {Futsal} from "../../interfaces/Futsal";


export function useFutsal(): [Futsal, (futsal:Futsal) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, Futsal>(state => state.futsal),
        futsal => dispatch(addFutsal(futsal))
    ]
}