import Carousel from "react-multi-carousel";
import React from "react";


interface CourtGalleryProps {
    futsal1: string;
}

const CourtGallery: React.FC<CourtGalleryProps> = ({ futsal1 }) => {
    const responsive = {
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
        },
    };

    return (
        <div className="courts-section-gallery">
            <div className="courts-gallery">
                <div className="courts-gallery-left-column">
                    <img src={futsal1}/>
                </div>
                <div className="courts-gallery-right-column">
                    <div className="right-upper-column">
                        <img src={futsal1}/>
                    </div>
                    <div className="right-lower-column">
                        <img src={futsal1}/>
                    </div>
                </div>
            </div>

            <div className="image-carousal">
                <Carousel
                    arrows={false}
                    responsive={responsive}
                    showDots={true}
                    swipeable={true}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={7750}
                >
                    <img src={futsal1} className="grid-image"/>
                    <img src={futsal1} className="grid-image"/>
                    <img src={futsal1} className="grid-image"/>
                </Carousel>
            </div>
        </div>

    )
}

export  default  CourtGallery