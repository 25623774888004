import React, { useEffect, useMemo, useRef, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../footer/Footer";
import { useUser } from "../../../reducers/ui/userHook";
import { axiosInstance } from "../../../utils/axiosInstance";
import { endPoints } from "../../../utils/endPoints";
import { Futsal } from "../../../interfaces/Futsal";
import CoverFlow from "../CoverFlow";
import Navigation from "../../navigation/Navigation";
import UpcomingBookings from "../UpcomingBookings";
import FeaturedPlacesToPlay from "../FeaturedPlacesToPlay";
import ThreeStepsBanner from "../ThreeStepsBanner";
import WelcomeToGearUp from "../WelcomeToGearUp";
import GetInTouchForm from "../GetInTouchForm";
// @ts-ignore
import { useCookies } from 'react-cookie';
import {sendGoogleAnalyticsEvent} from "../../../tracking/GoogleAnalytics";
import {events} from "../../../tracking/events/events";
import ReactGA from "react-ga4";




function Home() {
  const [user, setUser] = useUser();
  const [futsals, setFutsals] = useState<Futsal[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>("Colombo");
  const futsalContainerRef = useRef<HTMLDivElement>(null);
  const [time, setTime] = useState(new Date());
  const [isLoaded, setLoaded] = useState(true);




    useEffect(() => {

    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });

    axiosInstance
        .get(endPoints.GET_FUTSALS)
        .then((response) => {
          console.log(response.data);
          setFutsals(response.data);
          setLoaded(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

    // Set interval for updating time
    const intervalID = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);


  const getLocationButtonClass = (location: string) => {
    return selectedLocation === location
        ? "location-selected-btn"
        : "location-default-btn";
  };

  const scrollToExplore = () => {
      sendGoogleAnalyticsEvent(events.INPUT_CLICK, {
          clicked: `explore button clicked`,
      });
    if (futsalContainerRef && futsalContainerRef.current) {
      futsalContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const responsive = useMemo(() => {
    const calculateItemsToShow = (): number => {
      const availableWidth = window.innerWidth;
      const itemWidth = 300;
      return Math.floor(availableWidth / itemWidth);
    };

    const itemsToShow = calculateItemsToShow();

    return {
      computer: {
        breakpoint: { max: 4000, min: 1024 },
        items: itemsToShow,
        slidesToSlide: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 2,
      },
    };
  }, []);

  const renderLandingPage = () => (
      <div className="landing-page">
        <div className="background-with-players"></div>
        <div className="background-wording"></div>
        <div className="background-players"></div>
        <div className="background-explore-button" onClick={scrollToExplore}></div>
        <Navigation />
      </div>
  );

  return (
      <>
        {renderLandingPage()}
        <UpcomingBookings />
        <FeaturedPlacesToPlay
            futsals={futsals}
            selectedLocation={selectedLocation}
            isLoaded={isLoaded}
            futsalContainerRef={futsalContainerRef}
            handleLocationClick={(location: string) => setSelectedLocation(location)}
            navigateToViewAll={() => (window.location.href = "/viewAllFutsals")}
            getLocationButtonClass={(location: string) => getLocationButtonClass(location)}
            responsive={responsive}
        />
          <ThreeStepsBanner/>
          <GetInTouchForm/>
        <WelcomeToGearUp/>
        <CoverFlow />
        <Footer />
      </>
  );
}

export default Home;
