import React from "react";

const CourtNote=()=>{
    return (
        <div className="special-note-section">
            <div className="special-note-header">
                <h3>THINGS TO KNOW</h3>
            </div>
            <div className="special-note-body">
                <h4>Special Note</h4>
                <span>
              If you fail to show up for your futsal booking without prior
              cancellation or notice, you will not be eligible for a refund.
              Please note that this cancellation policy is subject to change
              without prior notice. We recommend reviewing the policy at the
              time of booking to ensure you are aware of any updates.
            </span>
            </div>
        </div>

    )
}
export default  CourtNote