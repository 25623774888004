import React from 'react';
import { IoClose } from 'react-icons/io5';

const CheckoutHeader = ({ stepNumber, stepTitle, handleClose }:{stepNumber:number,stepTitle:string,handleClose:()=>void}) => {
    return (
        <div className="date-time-bar">
            <div className="date-time-bar-topic">
                <p>Step {stepNumber} out of 3</p>
                <h3>{stepTitle}</h3>
            </div>
            <div className="date-time-bar-cross">
                <IoClose onClick={handleClose} />
            </div>
        </div>
    );
};

export default CheckoutHeader;
