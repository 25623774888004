import React, {useEffect, useState} from 'react';
import { GoogleLogin } from '@react-oauth/google';
import Logo from "../../asserts/Gearup logo  5.png";
import { axiosInstance } from "../../utils/axiosInstance";
import { endPoints } from "../../utils/endPoints";
import {User} from "../../interfaces/User";
import {toast, ToastContainer} from "react-toastify";
import ReactGA from "react-ga4";

interface SignUpData {
    name: string;
    picture:string;
    email: string;
    phoneNumber: string;
    passwordHash: string;
    district: string;
    gender: string;
    dateOfBirth: string;
}

function SignUp() {
    const [formData, setFormData] = useState<SignUpData>({
        picture: "image1",
        name: '',
        email: '',
        phoneNumber: '',
        passwordHash: '',
        district: 'Colombo',
        gender: 'male',
        dateOfBirth: ''
    });

    const [data, setData] = useState<any | undefined>(undefined);

    const responseMessage = (response: any) => {
        const token = response.credential;
        const [header, payload, signature] = token.split('.');
        const decodedPayload = JSON.parse(atob(payload));

        if (decodedPayload) {
            console.log(decodedPayload);
            setData(decodedPayload);
            sendSignUpDataToServer(decodedPayload.name, decodedPayload.email, decodedPayload.picture);
        } else {
            console.log('Decoded payload is undefined.');
        }
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/signup", title: "signup page" });
    }, []);


    const errorMessage = () => {
        console.log("An error occurred during Google signup.");
    };

    const sendSignUpDataToServer = (name: string , email: string , picture: string ) => {
        if (name && email && picture) {
            axiosInstance
                .post(endPoints.ADD_GOOGLE_USER, {
                    name: name,
                    email: email,
                    picture: picture
                },{ withCredentials: true })
                .then(response => {
                    if (response.data && response.data.status === "user Added as a User") {
                        console.log('User added successfully.');
                        localStorage.setItem('token', response.data.token);
                        window.location.href = "/";
                    } else {
                        toast.error(response.data.status)
                    }                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } else {
            console.log('Missing required data for sign up.');
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (formData.name && formData.email && formData.phoneNumber ) {
            axiosInstance
                .post(endPoints.ADD_USER, {
                   formData
                }, { withCredentials: true })
                .then(response => {
                    if (response.data && response.data.status === "user Added as a User") {
                        console.log('User added successfully.');
                        localStorage.setItem('token', response.data.token);
                        window.location.href = "/";
                    } else {
                        toast.error(response.data.status)
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } else {
            console.log('Missing required data for sign up.');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <div className="signup-page">
                <img src={Logo} alt="Logo" className="sign-logo"></img>
                <div className="signup-content">
                    <h2>SIGN UP</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="signup-form-group">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                required={true}
                            />
                        </div>
                        <div className="signup-form-group">
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required={true}
                            />
                        </div>
                        <div className="signup-form-group">
                            <select
                                id="gender"
                                name="gender"
                                placeholder="Gender"
                                value={formData.gender}
                                onChange={handleChange}
                            >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className="signup-form-group">
                            <input
                                type="date"
                                id="dateOfBirth"
                                name="dateOfBirth"
                                placeholder="Date of Birth"
                                value={formData.dateOfBirth}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="signup-form-group">
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="Phone Number"
                                value={formData.phoneNumber}
                                required={true}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="signup-form-group">
                            <input
                                type="password"
                                id="passwordHash"
                                name="passwordHash"
                                placeholder="Password"
                                value={formData.passwordHash}
                                required={true}
                                onChange={handleChange}

                            />
                        </div>
                        <div className="signup-form-group">
                            <select
                                id="district"
                                name="district"
                                placeholder="District"
                                value={formData.district}
                                onChange={handleChange}
                            >
                                <option value="Colombo">Colombo</option>
                                <option value="Kandy">Kandy</option>
                                <option value="Galle">Galle</option>
                            </select>
                        </div>
                        <button type="submit">Sign Up</button>
                    </form>
                    <p>OR</p>
                    <div className="google-sign-in">
                        <GoogleLogin onSuccess={responseMessage} onError={errorMessage}/>
                    </div>
                    <p>Already have an account? <a href="/login" className="sub-text">Login</a></p>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    );
};

export default SignUp;
