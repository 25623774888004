import { ADD_FUTSAL_ID} from "../actionTypes/actionTypes";
import {Futsal} from "../interfaces/Futsal";


interface FutsalIdAction {
    type: string;
    payload: Number;
}

const initialState = 0

const futsalIdReducer = (state = initialState, action:FutsalIdAction):Number => {
    switch (action.type) {
        case ADD_FUTSAL_ID:
            return action.payload;
        default:
            return state;
    }
};

export default futsalIdReducer;
