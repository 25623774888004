import React, { useEffect, useState } from 'react';
import Profile from "../../asserts/profile.jpg";
import '../../App.css';
import Navigation from "../navigation/Navigation";
import { axiosInstance } from "../../utils/axiosInstance";
import { endPoints } from "../../utils/endPoints";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { User } from "../../interfaces/User";

function EditProfile() {
    const [formData, setFormData] = useState({
        userName: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        district: '',
        gender: '',
    });
    const [cookies, , removeCookie] = useCookies(["token"]);
    const [userEmail, setUserEmail] = useState();
    const [userDetails, setUserDetails] = useState<User>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyCookie = async () => {
            const token=localStorage.getItem("token")
            const { data } = await axiosInstance.post(
                endPoints.USER_AUTHENTICATION,
                {token},
                { withCredentials: true }
            );
            const { status, user, name } = data;
            setUserEmail(user);
            return status ? "" : localStorage.removeItem("token");
        };
        verifyCookie();
    }, [cookies, removeCookie]);

    useEffect(() => {
        if (userEmail) {
            axiosInstance.get(`${endPoints.GET_USER}${userEmail}`)
                .then(response => {
                    if (response.data.status === 'ok') {
                        setUserDetails(response.data.user);
                        setFormData({
                            userName: response.data.user.name,
                            email: response.data.user.email,
                            phoneNumber: response.data.user.phoneNumber,
                            dateOfBirth: response.data.user.dateOfBirth ? new Date(response.data.user.dateOfBirth).toISOString().split('T')[0] : '',
                            district: response.data.user.district,
                            gender: response.data.user.gender,
                        });
                        setLoading(false); // Set loading to false when data is fetched
                    }
                })
                .catch(error => {
                    console.error('Error fetching user details:', error);
                    setLoading(false); // Set loading to false in case of error
                });
        }
    }, [userEmail]);

    const formatPhoneNumber = (phoneNumber:string) => {
        if (/^\d+$/.test(phoneNumber)) {
            if (phoneNumber.startsWith('7')) {
                return `94${phoneNumber}`;
            } else if (phoneNumber.startsWith('0')) {
                return `94${phoneNumber.substring(1)}`;
            } else {
                return phoneNumber;
            }
        } else {
            return phoneNumber;
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = () => {
        const formattedPhoneNumber = formatPhoneNumber(formData.phoneNumber);

        axiosInstance.put(`${endPoints.UPDATE_USER}${userEmail}`,  { ...formData, phoneNumber: formattedPhoneNumber })
            .then(response => {
                toast.success("Profile updated successfully");
            })
            .catch(error => {
                console.error('Error updating user profile:', error);
                toast.error("Failed to update profile");
            });
    };

    const handleCancel = () => {
        // Reset the form data to its initial state
        setFormData(prevState => ({
            ...prevState,
            userName: userDetails?.name || '',
            email: userDetails?.email || '',
            phoneNumber: userDetails?.phoneNumber || '',
            dateOfBirth: typeof userDetails?.dateOfBirth === 'string' ? userDetails.dateOfBirth : userDetails?.dateOfBirth instanceof Date ? userDetails.dateOfBirth.toISOString().split('T')[0] : '',
            gender: userDetails?.gender || '',
        }));
    };


    return (
        <>
            <div className="profile-container">
                <Navigation />
                <div className="edit-profile-heading">
                    <h2>Edit Profile</h2>
                </div>
                <div className="edit-profile-card">
                    <div className="edit-profile-content">
                        <div className="profile-picture">
                            <img src={Profile} alt="Profile" />
                        </div>
                        <div className="edit-profile-section">
                            <label htmlFor="username">Name</label>
                            <input type="text" id="username" name="userName" value={formData.userName} onChange={handleChange} />
                        </div>
                        <div className="edit-profile-section">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} disabled  />
                        </div>
                        <div className="edit-profile-section">
                            <label htmlFor="phone">Phone Number</label>
                            <input type="tel" id="phone" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
                        </div>
                        <div className="edit-profile-section">
                            <label htmlFor="dob">Date of Birth</label>
                            <input type="date" id="DOB" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} />
                        </div>
                        <div className="edit-profile-section">
                            <label>Gender:</label>
                            <div className="gender-checkboxes">
                                <label>
                                    <input type="checkbox" name="gender" value="male" checked={formData.gender === 'male'} onChange={handleChange} />
                                    Male
                                </label>
                                <label>
                                    <input type="checkbox" name="gender" value="female" checked={formData.gender === 'female'} onChange={handleChange} />
                                    Female
                                </label>
                                <label>
                                    <input type="checkbox" name="gender" value="other" checked={formData.gender === 'other'} onChange={handleChange} />
                                    Other
                                </label>
                            </div>
                        </div>
                        <div className="edit-profile-section-buttons">
                            <button className="cancel-button" onClick={handleCancel}>
                                Cancel
                            </button>
                            <button className="save-button" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                    <div className="edit-profile-password">
                        <h3>Change Password</h3>
                        <div className="edit-profile-password-section">
                            <label>
                                Current Password
                            </label><input type="password" id="password" name="password" required />
                        </div>
                        <div className="edit-profile-password-section">
                            <label>
                                New Password
                            </label>
                            <input type="password" id="password" name="password" required />
                        </div>
                        <div className="edit-profile-password-section">
                            <label>
                                Confirm Password
                            </label>
                            <input type="password" id="password" name="password" required />
                        </div>
                        <div className="edit-profile-password-section-buttons">
                            <button className="cancel-btn">
                                Cancel
                            </button>
                            <button className="change-btn">
                                Change
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditProfile;
