import React from "react";
import Skeleton from "react-loading-skeleton";
import "../skeletonCss/BookingCardSkeletonCss.css";
import "react-loading-skeleton/dist/skeleton.css";

function BookingCardSkeleton() {
  return (
    <>
        <div className='bookingCardSkeleton-container'>
        <div className="bookingCardSkeleton-details-container">
        <div className="bookingCardSkeleton-name-container">
            <Skeleton
              className="bookingCard-name-skeleton"
              width={window.innerWidth >= 1024 ? 250 : 100}
              height={window.innerWidth >= 1024 ? 30 : 20}
            />
          </div>
          <div className="bookingCardSkeleton-location-container">
            <Skeleton
              className="bookingCard-location-skeleton"
              width={window.innerWidth >= 1024 ? 250 : 100}
              height={window.innerWidth >= 1024 ? 30 : 20}
            />
          </div>
        </div>

        <div className="bookingCardSkeleton-booking-container">
          <div className="bookingCardSkeleton-court-id-container">
          <div className="bookingCardSkeleton-court-container">
              <Skeleton
                className="bookingCard-court-skeleton"
                width={window.innerWidth >= 1024 ? 100 : 50}
                height={window.innerWidth >= 1024 ? 40 : 30}
              />
            </div>
            <div className="bookingCardSkeleton-id-container">
              <Skeleton
                className="bookingCard-id-skeleton"
                width={window.innerWidth >= 1024 ? 100 : 50}
                height={window.innerWidth >= 1024 ? 40 : 30}
              />
            </div>
          </div>

        </div>

        <div className="bookingCardSkeleton-booking-container">
          <div className="bookingCardSkeleton-date-time-container">
          <div className="bookingCardSkeleton-date-container">
              <Skeleton
                className="bookingCard-time-skeleton"
                width={window.innerWidth >= 1024 ? 100 : 50}
                height={window.innerWidth >= 1024 ? 40 : 30}
              />
            </div>
            <div className="bookingCardSkeleton-time-container">
              <Skeleton
                className="bookingCard-time-skeleton"
                width={window.innerWidth >= 1024 ? 100 : 50}
                height={window.innerWidth >= 1024 ? 40 : 30}
              />
            </div>
          </div>

        </div>
        </div>
    </>
  )
}

export default BookingCardSkeleton