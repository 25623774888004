import React, {CSSProperties, useEffect, useState} from 'react';
import { GoogleLogin } from '@react-oauth/google';
import Logo from '../../asserts/Gearup logo  5.png';
import {axiosInstance} from "../../utils/axiosInstance";
import {endPoints} from "../../utils/endPoints";
import RiseLoader from "react-spinners/RiseLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @ts-ignore
import {useCookies} from "react-cookie";
import ReactGA from "react-ga4";




function Login() {
    const [isLoading,setIsloading]=useState(false);
    const [formData, setFormData] = useState({
        email: '',
        passwordHash: ''
    });
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/login", title: "Login page" });
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const override: CSSProperties = {
        position: "absolute",
        top: "50%",
        right: "45%"
    };

    const mobileOverride: CSSProperties = {
        position: "absolute",
        top: "50%",
        right: "27%"
    };

    const responseMessage = (response: any) => {
        // console.log("response",response);
        const token = response.credential;
        // console.log(token)
        const [header, payload, signature] = token.split('.');
        const decodedPayload = JSON.parse(atob(payload));
        verifyLogin(decodedPayload.email,decodedPayload.name)

    };
    const errorMessage = () => {
        console.log("An error occurred during Google login.");
    };

    const verifyLogin = ( email: string,name:string ) => {
        if (email) {
            setIsloading(true)
            axiosInstance.post(endPoints.GOOGLE_LOGIN,{email:email}, { withCredentials: true})
                .then(response => {
                    if (response.data.status === 'ok') {
                        setIsloading(false)
                        localStorage.setItem('token', response.data.token);
                        console.log("headers",response.headers)
                        window.location.href = "/";
                    }
                })
                .catch(error => {
                    setIsloading(false)
                    toast.error(`Hi ${name}, Please Register the email first`)
                    console.error('Error fetching user details:', error);
                });

        } else {
            console.log('Missing required data for sign up.');
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsloading(true);
        const { email, passwordHash } = formData;
        axiosInstance.post(endPoints.USER_LOGIN, { email, passwordHash }, { withCredentials: true })
            .then(response => {
                setIsloading(false);
                if (response.data.status === 'ok') {
                    localStorage.setItem('token', response.data.token);
                    window.location.href = "/";
                }
                else {
                    toast.error(response.data.status);

                }
            })
            .catch(error => {
                setIsloading(false);
                toast.error('Invalid username or password');
                console.error('Error logging in:', error);
            });
    };


    return (
        <>
            <RiseLoader
                color={"#79e840"}
                cssOverride={window.innerWidth <= 768 ? mobileOverride : override}
                loading={isLoading}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            <div className="login-page">
                <img src={Logo} className="sign-logo" alt="Logo"></img>
                <div className="login-form-container">
                    <h2>LOGIN</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="login-form-group">
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="login-form-group">
                            <input
                                type="password"
                                id="passwordHash"
                                name="passwordHash"
                                required
                                placeholder="Password"
                                value={formData.passwordHash}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit">Sign In</button>
                        <p> OR </p>
                        <div className="google-sign-in">
                            <GoogleLogin onSuccess={responseMessage} onError={errorMessage}/>
                        </div>
                    </form>
                    <p>Don't have an account? <a href="/signup" className="sub-text">Sign Up</a></p>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    )
}

export default Login;
