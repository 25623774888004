
export const endPoints ={
    GET_FUTSALS:"/futsal/courts",
    GET_FUTSAL:"/futsal/courts/",
    ADD_BOOKING:"/booking/addBooking/",
    GET_BOOKINGS:"/booking/getBooking/",
    GET_TIME_SLOT:"/booking/check-time-slot/",
    GET_FUTSAL_IMAGE:"/futsal/image/",
    ADD_REVIEW:"/rating/addRating",
    GET_REVIEW:"/rating/get-rating/",
    GET_USER:"/users/getUser/",
    USER_LOGIN:"/users/login/",
    GOOGLE_LOGIN:"/users/google-login",
    GET_PAYMENT_PORTAL_HASH:"/generate-hash/",
    ADD_GOOGLE_USER:"/users/google-signup",
    LOGOUT:"/users/logout",
    ADD_USER:"/users/adduser/",
    GET_USER_BOOKINGS:"/booking/getUserBooking/",
    UPDATE_USER:"/users/updateUser/",
    USER_AUTHENTICATION:"/userVerification"
}