import { ADD_REVIEW } from "../actionTypes/actionTypes";
import {Review} from "../interfaces/Review";


interface ReviewAction {
    type: string;
    payload: Review[];
}

const initialState: Review[] = []

const offerReducer = (state = initialState, action: ReviewAction): Review[] => {
    switch (action.type) {
        case ADD_REVIEW:
            return action.payload;
        default:
            return state;
    }
};

export default offerReducer;
