import Profile from "../../asserts/profile.jpg";
import { AiFillStar } from "react-icons/ai";
import React, {CSSProperties, useEffect, useState} from "react";
import { Review } from "../../interfaces/Review";
import { endPoints } from "../../utils/endPoints";
import { axiosInstance } from "../../utils/axiosInstance";
import { Futsal } from "../../interfaces/Futsal";
import { User } from "../../interfaces/User";
import { RxStarFilled } from "react-icons/rx";
import { TbStarHalfFilled } from "react-icons/tb";
import { FaRegStar } from "react-icons/fa";
import { CgArrowLongRight } from "react-icons/cg";
import { CgArrowLongDown } from "react-icons/cg";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { VscEyeClosed} from "react-icons/vsc";
import RiseLoader from "react-spinners/RiseLoader";

const CourtReview = ({
  reviews,
  futsal,
  user,
  setReviews,
  isAllowed
}: {
  reviews: Review[];
  futsal: Futsal;
  user: User;
  isAllowed:Boolean;
  setReviews:React.Dispatch<React.SetStateAction<Review[]>>
}) => {
  const [rating, setRating] = useState<number>(5);
  const [sort, setSort] = useState<String>("NEWEST");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [showMoreReviews, setShowMoreReviews] = useState<boolean>(false);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [reviewsToShow, setReviewsToShow] = useState<number>(6);
  const [showReviewForm, setShowReviewForm] = useState<boolean>(false);
  const [reviewText, setReviewText] = useState<string>("");
  const [userImages, setUserImages] = useState<{ [email: string]: string }>({});
  const [isLoading,setIsloading]=useState(false);


  const override: CSSProperties = {
    position: "absolute",
    top: "50%",
    right: "35%"
  };

  const mobileOverride: CSSProperties = {
    position: "absolute",
    top: "50%",
    right: "27%"
  };


  const averageRating =
    reviews.length > 0
      ? reviews.reduce((sum, review) => sum + review.ratingPoint, 0) /
        reviews.length
      : 0;

  const generateStars = (averageRating: number) => {
    const stars = [];
    const fullStars = Math.floor(averageRating);
    for (let i = 0; i < fullStars; i++) {
      stars.push(<RxStarFilled size={20} key={i} />);
    }
    const decimalPart = averageRating - fullStars;
    if (decimalPart > 0) {
      stars.push(<TbStarHalfFilled size={20} key="half" />);
    }
    const remainingStars = 5 - stars.length;
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<FaRegStar size={20} key={`empty_${i}`} />);
    }
    return stars;
  };

  const handleShowMoreReviews = () => {
    setReviewsToShow(reviewsToShow + 6);
  };
  const handleSelectButton = (option: string) => {
    if (selectedFilters.includes(option)) {
      setSelectedFilters(selectedFilters.filter((filter) => filter !== option));
    } else {
      setSelectedFilters([...selectedFilters, option]);
    }
  };

  const handleSelect = (option: String) => {
    setSort(option);
    setShowDropDown(false);
  };

  useEffect(() => {
    console.log(rating, reviewText);
  }, [rating, reviewText]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsloading(true)
    try {
      const endPoint = endPoints.ADD_REVIEW;
      const response = await axiosInstance.post(endPoint, {
        futsalId: futsal.futsalId,
        ratingPoint: rating,
        message: reviewText,
        customerName: user.name,
        email:user.email,
        date:new Date()
      });
      const newReview: Review = response.data.rating;
      console.log("£",newReview)
      setReviews([...reviews, newReview]);
      console.log("CourtReview updated successfully");

    } catch (error) {
      console.error("Error while  adding data:", error);
    }
    setIsloading(false)
    setRating(5);
    setReviewText("");
    setShowReviewForm(false);
  };


  useEffect(() => {
    const fetchUserImages = async () => {
      const images: { [email: string]: string } = {};
      for (const review of reviews) {
        if (!userImages[review.email]) {
          try {
            const imageUrl = await getUserImage(review.email);
            images[review.email] = imageUrl;
          } catch (error) {
            console.error('Error fetching user image:', error);
          }
        }
      }
      setUserImages(prevImages => ({ ...prevImages, ...images }));
    };

    fetchUserImages();
  }, [reviews]);


  const getUserImage = async (email: string) => {
    try {
      const response = await axiosInstance.get(`${endPoints.GET_USER}${email}`)

      if (response.data.status === 'ok') {
        return response.data.user.picture.toString();
      }
    } catch (error) {
      console.error('Error fetching user image:', error);
      throw error;
    }
  };

  return (
    <div className="reviews-section">
      <div className="reviews-section-header">
        <h2>Reviews ({reviews.length})</h2>
        <div className="reviews-header-container">
          <div className="reviews-count-container">
            <h1>{averageRating.toFixed(1)}</h1>
            <span className="reviews-stars-bottom">
              {generateStars(averageRating)}
            </span>
          </div>
          <div>
            {isAllowed && <button
                className="add-review-btn"
                onClick={() => setShowReviewForm(true)}
            >
              WRITE A REVIEW&nbsp; &nbsp; <CgArrowLongRight size={30}/>
            </button>}
          </div>
        </div>
      </div>
      <div className="reviews-container">
        <div className="review-select-container">
          <div className="reviews-filter-container">
            <p>Filter by star rating</p>
            <div className="reviews-filter-buttons">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  className={`reviews-filter-btn ${
                    selectedFilters.includes(String(star))
                      ? "selected-review-btn"
                      : ""
                  }`}
                  key={star}
                  onClick={() => handleSelectButton(String(star))}
                >
                  {star} &nbsp; <RxStarFilled />
                </button>
              ))}
              <div />
            </div>
          </div>

          <div className="reviews-sort-container">
            <p>Sort by</p>
            <div className="review-sort-dropdown">
              <div className="dropdown">
                <button
                  className="drop-btn"
                  onClick={() => setShowDropDown(!showDropDown)}
                >
                  {sort} &nbsp;
                  {showDropDown ? (
                    <IoIosArrowUp size={20} />
                  ) : (
                    <IoIosArrowDown size={20} />
                  )}{" "}
                </button>
                <div
                  className={`dropdown-content ${
                    showDropDown ? "show-dropdown" : ""
                  }`}
                >
                  <li onClick={() => handleSelect("NEWEST")}>NEWEST</li>
                  <li onClick={() => handleSelect("HIGHEST RATED")}>
                    HIGHEST RATED
                  </li>
                  <li onClick={() => handleSelect("LOWEST RATED")}>
                    LOWEST RATED
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="reviews-section-customer-reviews">
          {reviews &&
            reviews
              .sort((a, b) => {
                if (sort === "NEWEST") {
                  return new Date(b.date).getTime() - new Date(a.date).getTime();
                } else if (sort === "HIGHEST RATED") {
                  return b.ratingPoint - a.ratingPoint;
                } else if (sort === "LOWEST RATED") {
                  return a.ratingPoint - b.ratingPoint;
                }
                return 0;
              })
              .filter(
                (review) =>
                  selectedFilters.length === 0 ||
                  selectedFilters.includes(String(review.ratingPoint))
              )
              .slice(0, reviewsToShow)
              .map((review, index) => (
                  <div className="review-container" key={index}>
                    <div className="name-and-pic">
                      <img src={userImages[review.email] || Profile} alt="Profile"/>
                      <div>
                        <p className="reviewer-name">{review.customerName}.</p>
                        <div className="stars">
                          {Array.from({length: review.ratingPoint}).map(
                              (_, starIndex) => (
                                  <AiFillStar
                                      key={starIndex}
                                      style={{fontSize: "10px"}}
                                  />
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="customer-stars-and-review">
                      <p className="review-message">{review.message}</p>
                    </div>
                    <p className="review-date">{new Date(review.date).toLocaleDateString()}</p>

                  </div>
              ))}

        </div>
        {reviews.length > reviewsToShow && (
            <button
                className="read-more-reviews"
                onClick={handleShowMoreReviews}
            >
              READ MORE REVIEWS &nbsp;
              <CgArrowLongDown size={20} />
            </button>
        )}
      </div>

      <div
        className={`review-form-panel ${showReviewForm ? "active-form" : ""}`}
      >
        <RiseLoader
            color={"#79e840"}
            cssOverride={window.innerWidth <= 768 ? mobileOverride : override}
            loading={isLoading}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
         <div className="close-icon-container">
             <VscEyeClosed className="close-icon" size={30} onClick={() => setShowReviewForm(false)} />

         </div>
          <h1>WRITE YOUR REVIEW</h1>
        <div className="rating-input-container">
          <p>Your overall rating?</p>
          <span className="reviews-section-stars">
            {Array.from({ length: 5 }).map((_, index) => (
              <AiFillStar
                key={index}
                color={index < rating ? "gold" : "gray"}
                onClick={() => setRating(index + 1)}
              />
            ))}
          </span>
        </div>
        <div className="message-input-container">
          <p>Share your experience</p>
          <textarea
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
            placeholder="Tell other people more about the court. What about the quality? Or the comfort?"
          />
        </div>
        <button className="rating-form-btn" onClick={handleSubmit}>
          SUBMIT
        </button>
      </div>
    </div>
  );
};
export default CourtReview;
