import {useDispatch, useSelector} from "react-redux";
import {User} from "../../interfaces/User";
import {addUser} from "../../actions/userAction";
import {RootState} from "../store";


export function useUser(): [User, (user:User) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, User>(state => state.user),
        user => dispatch(addUser(user))
    ]
}