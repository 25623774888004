import { ADD_FUTSAL} from "../actionTypes/actionTypes";
import {Futsal} from "../interfaces/Futsal";

interface FutsalAction {
    type: string;
    payload: Futsal;
}

const initialState: Futsal = {
    futsalId: 0,
    name: "",
    address:"",
    url:"",
    place:"",
    district:"",
    contact: {
        email:"",
        mobile:"",
    },
    startTime: "",
    endTime: "",
    courtType:[] ,
    numberOfCourt:0,
    pricePerHour:{
        day:0,
        night:0
    },
    courts:[],
    description:"",
    available: false
};

const futsalReducer = (state = initialState, action: FutsalAction): Futsal => {
    switch (action.type) {
        case ADD_FUTSAL:
            return action.payload;
        default:
            return state;
    }
};

export default futsalReducer;
