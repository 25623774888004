import React from "react";

const CourtAbout=()=>{
    return (
        <div className="about-section">
            <h2>About</h2>
            <p>
                Renowned for its welcoming surroundings and authentic experience,
                Huckle The Barber offers a range of traditional services in a
                relaxed, modern setting. With stores in the heart of Shoreditch
                and Holborn, Huckle The Barber is recognised for its roster of
                skilled barbers and easy hospitality. Personally selected by our
                found
            </p>
        </div>

    )
}
export default  CourtAbout