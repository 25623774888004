import React, {useState} from "react";
import {Futsal} from "../../interfaces/Futsal";

const Services=({futsal}:{futsal:Futsal})=>{

    const [activeContent, setActiveContent] = useState<string>("featured");
    const showContent = (contentId: string) => {
        setActiveContent(contentId);
    };
    return (
        <div className="services-section">
            <h2>Services</h2>
            <div className="services-section-body">
                <div className="service-card">
                    <div className="service-card-nav">
                        <a
                            onClick={() => showContent("featured")}
                            className={activeContent === "featured" ? "selected" : ""}
                        >
                            Featured
                        </a>
                        <a
                            onClick={() => showContent("amenities")}
                            className={activeContent === "amenities" ? "selected" : ""}
                        >
                            Amenities
                        </a>
                        <a
                            onClick={() => showContent("prices")}
                            className={activeContent === "prices" ? "selected" : ""}
                        >
                            Prices
                        </a>
                        <a
                            onClick={() => showContent("policies")}
                            className={activeContent === "policies" ? "selected" : ""}
                        >
                            Policies
                        </a>
                    </div>
                    <div className="service-content">
                        {activeContent === "featured" && (
                            <div className="service-featured">
                                <h3>Court Information</h3>
                                <p>{futsal.description}</p>
                            </div>
                        )}
                        {activeContent === "amenities" && (
                            <div className="service-amenities">
                                <h3>Amenities</h3>
                                <p className="sub-text">Service Provided By Court</p>
                            </div>
                        )}
                        {activeContent === "prices" && (
                            <p>Prices content goes here.</p>
                        )}
                        {activeContent === "policies" && (
                            <div className="service-policies">
                                <h3>Cancellation Policy</h3>
                                <ul>
                                    Cancellation By Customer
                                    <li>
                                        If you need to cancel your futsal booking, please do
                                        so at least 24 hours before the scheduled start time.
                                    </li>
                                    <li>
                                        Cancellations made more than 24 hours in advance will
                                        receive a full refund of the booking fee.
                                    </li>
                                    <li>
                                        Cancellations made within 24 hours of the scheduled
                                        start time will not be eligible for a refund.
                                    </li>
                                </ul>
                                <br/>
                                <ul>
                                    Cancellation By Futsal Facility
                                    <li>
                                        In the rare event that the futsal facility cancels
                                        your booking due to unforeseen circumstances, such as
                                        maintenance or unexpected closure, we will provide a
                                        full refund of the booking fee.
                                    </li>
                                    <li>
                                        We will make every effort to notify you promptly if
                                        such a situation arises.
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Services