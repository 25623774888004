import { useEffect, useState } from "react";
import Logo from '../../asserts/GearupLogo-black.svg';
import Profile from '../../asserts/profile.jpg'
import Navigation from "../navigation/Navigation";

function TokensPage() {

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [firstWord, setFirstWord] = useState('');
    const [lastWord,setLastWord] = useState('');

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    
    const courtName = "COLOMBO FUTSAL"

    useEffect(()=>{
        const splitWord = courtName.split(" ")
        const first = splitWord[0];
        const last = splitWord[1];

        setFirstWord(first);
        setLastWord(last);
    },[courtName])
    

    
    return(
        <>
            <Navigation/>
            <div className="tokens-content">
                <div className="individual-token">
                    <div className="token-header">
                        <p className="token-username green"> z.xiid</p>
                        <p className="token-user-id white">#GU1034</p>
                    </div>
                    <div className="token-body">
                        <p className="first-word">{firstWord}</p>
                        <p className="last-word">{lastWord}</p>
                    </div>
                    <div className="token-footer">
                        <div className="token-footer-duration">
                            <p className="green">2 HOURS</p>
                            <p className="white" id="directing-lines"> &nbsp; -----------</p>
                        </div>
                        <button>USE TICKET</button>
                    </div>
                </div>

                <div className="individual-token">
                    <div className="token-header">
                        <p className="token-username green"> z.xiid</p>
                        <p className="token-user-id white">#GU1034</p>
                    </div>
                    <div className="token-body">
                        <p className="first-word">{firstWord}</p>
                        <p className="last-word">{lastWord}</p>
                    </div>
                    <div className="token-footer">
                        <div className="token-footer-duration">
                            <p className="green">2 HOURS</p>
                            <p className="white" id="directing-lines"> &nbsp; -----------&gt;</p>
                        </div>
                        <button>USE TICKET</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TokensPage