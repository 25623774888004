import { ADD_FUTSAL } from "../actionTypes/actionTypes";
import {Futsal} from "../interfaces/Futsal";

const addFutsal = (futsal:Futsal) => {
    return {
        type: ADD_FUTSAL,
        payload: futsal,
    };
};

// const deleteItem = () => {
//     return {
//         type: DELETE_ITEM,
//     };
// };

export { addFutsal };