import React, { useCallback, useEffect, useState } from "react";
import ViewAllFutsalCard from "../interfaces/cards/ViewAllFutsalCard";
import { Futsal } from "../../interfaces/Futsal";
import { endPoints } from "../../utils/endPoints";
import { axiosInstance } from "../../utils/axiosInstance";
import { Review } from "../../interfaces/Review";
import Courts from "../court/Court/Courts";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineStar } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import { GrFilter } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import ViewAllCourtCardSkeleton from "../skeletonLoaders/ViewAllCourtCardSkeleton";
import CourtSkeleton from "../skeletonLoaders/CourtSkeleton";
import Carousel from "react-multi-carousel";
import { MdArrowForwardIos } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";
import NoFutsal from "../../asserts/No-futsal.png";
import ReactGA from "react-ga4";


function ViewAllFutsal(props: any) {
  const [futsals, setFutsals] = useState<Futsal[]>([]);
  const [originalFutsals, setOriginalFutsals] = useState<Futsal[]>([]);
  const [sort, setSort] = useState<string>("");
  const [selectedLocation, setSelectedLocation] = useState<String>("Colombo");
  const [showCourtType, setshowCourtType] = useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState<string | null>(null);
  const [priceFilter, setPriceFilter] = useState<boolean>(false);
  const [ratingFilter, setRatingFilter] = useState<boolean>(false);
  const [priceButtonClicked, setPriceButtonClicked] = useState<string>("");
  const [rateButtonClicked, setRateButtonClicked] = useState<string>("");
  const [sportButtonClicked, setSportButtonClicked] = useState<string>("");

  const [mobileSidebarVisible, setMobileSidebarVisible] = useState(false);
  const [pcSidebarVisible, setPcSidebarVisible] = useState(false);

  const [arrowDirections, setArrowDirections] = useState({
    rating: "down",
    sports: "down",
    price: "down",
  });
  const [isLoaded, setLoaded] = useState(true);

  const [selectedRatings, setSelectedRatings] = useState<number[]>([]);

  const [isSlideOut, setSlideOut] = useState(false);

  const [startIndex, setStartIndex] = useState<number>(0);

  const buttons = ["Colombo", "Kandy", "Galle", "Negambo", "Matara", "Kegalle", "Gampaha"];
  const buttonsToShow = 3;

  // In the useEffect, include selectedRatings as a dependency
  useEffect(() => {
    applyFilters();
  }, [
    selectedLocation,
    priceButtonClicked,
    sportButtonClicked,
    selectedRatings,
    ratingFilter,
  ]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
    setSelectedButton("Colombo");
    fetchFutsalData();
  }, []);


  const applyFilters = () => {
    let filteredFutsals = [...originalFutsals];

    if (selectedLocation) {
      filteredFutsals = filteredFutsals.filter(
        (futsal) => futsal.district === selectedLocation
      );
    }

    // if(selectedFilterCourtType) {
    //   filteredFutsals = filteredFutsals.filter(
    //     (futsal) => futsal.courtType.includes(selectedFilterCourtType))
    // }
    if (sportButtonClicked) {
      filteredFutsals = filteredFutsals.filter((futsal) =>
        futsal.courtType.includes(sportButtonClicked)
      );
    }

    if (selectedRatings.length > 0) {
      filteredFutsals = filteredFutsals.filter((futsal) => {
        const averageRating = calculateAverageRating(futsal.reviews);
        return selectedRatings.includes(Math.floor(averageRating));
      });
    }

    if (priceButtonClicked === "highToLow") {
      filteredFutsals.sort(
        (a, b) =>
          getCurrentPrice(b.pricePerHour) - getCurrentPrice(a.pricePerHour)
      );
    } else if (priceButtonClicked === "lowToHigh") {
      filteredFutsals.sort(
        (a, b) =>
          getCurrentPrice(a.pricePerHour) - getCurrentPrice(b.pricePerHour)
      );
    }

    if (rateButtonClicked === "highToLowRate") {
      filteredFutsals.sort(
        (a, b) =>
          calculateAverageRating(b.reviews) - calculateAverageRating(a.reviews)
      );
    } else if (sort === "lowToHighRate") {
      filteredFutsals.sort(
        (a, b) =>
          calculateAverageRating(a.reviews) - calculateAverageRating(b.reviews)
      );
    }

    setFutsals(filteredFutsals);
  };
  //futsal-data
  const fetchFutsalData = async () => {
    try {
      const endPoint = endPoints.GET_FUTSALS;
      const response = await axiosInstance.get(endPoint);
      const futsalData = response.data || [];
      const sortedFutsals = [...futsalData].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      // Fetch review data for each futsal
      const futsalsWithReviews = [];

      for (const futsal of sortedFutsals) {
        const reviewResponse = await axiosInstance.get(
          `${endPoints.GET_REVIEW}${futsal.futsalId}`
        );
        const reviews = reviewResponse.data.allRating || [];

        // Use push to append reviews to the current futsal
        const futsalWithReviews = { ...futsal, reviews };
        futsalsWithReviews.push(futsalWithReviews);
      }

      setFutsals(futsalsWithReviews);
      setLoaded(false);
      setOriginalFutsals(futsalsWithReviews);
      console.log("get futsals1", response);
    } catch (error) {
      console.error("Error fetching futsal data:", error);
    }
  };

  const handleButtonClick = (button: string) => {
    setSelectedButton(selectedButton === button ? null : button);
  };

  //filter-district
  const handleLocationClick = (location: string) => {
    if (selectedButton === location) {
      return;
    }
    setSelectedLocation(location);

    const filteredFutsals = originalFutsals.filter(
      (futsal) => futsal.district === location
    );

    handleButtonClick(location);
    setFutsals(filteredFutsals);

    applyFilters();
  };

  //filter-court-btn
  const courtBtnClick = (selectedType: string) => {
    setSportButtonClicked(selectedType);

    // Filter futsals based on selected location and court type
    let filteredFutsals = originalFutsals.filter((futsal) => {
      return (
        futsal.district === selectedLocation &&
        futsal.courtType.includes(selectedType)
      );
    });

    // Apply other filters if they are active
    if (selectedRatings.length > 0) {
      filteredFutsals = filteredFutsals.filter((futsal) => {
        const averageRating = calculateAverageRating(futsal.reviews);
        return selectedRatings.includes(Math.floor(averageRating));
      });
    }

    // Set the sorted type
    setSort(selectedType);

    // Set the filtered futsals
    setFutsals(filteredFutsals);
  };

  //unique-coutTypes-ignore-multiple
  const getUniqueCourtTypes = (): string[] => {
    const allCourtTypes: string[] = [];
    originalFutsals.forEach((court) => {
      allCourtTypes.push(...court.courtType);
    });

    const uniqueCourtTypesSet = new Set(allCourtTypes);
    return Array.from(uniqueCourtTypesSet);
  };

  //curret-price
  const getCurrentPrice = (price: { day: number; night: number }): number => {
    const currentHour = new Date().getHours();
    // console.log("current hour", currentHour);

    const isDayTime = currentHour >= 6 && currentHour < 18;
    return isDayTime ? price.day : price.night;
  };

  const sortFutsalsPrice = (sortType: string) => {
    const sortedFutsals = [...futsals];

    if (sortType === "highToLow") {
      sortedFutsals.sort(
        (a, b) =>
          getCurrentPrice(b.pricePerHour) - getCurrentPrice(a.pricePerHour)
      );
    } else if (sortType === "lowToHigh") {
      sortedFutsals.sort(
        (a, b) =>
          getCurrentPrice(a.pricePerHour) - getCurrentPrice(b.pricePerHour)
      );
    }
    setFutsals(sortedFutsals);
  };
  //button-sort-price-2
  const handleSortPriceBtn = (sortType: string) => {
    applyFilters();
    setRateButtonClicked("");
    setPriceButtonClicked(sortType);
    setSort(sortType);
    sortFutsalsPrice(sortType);
  };

  //average-rating
  const calculateAverageRating = (reviews: Review[]): number => {
    if (reviews.length === 0) return 0;

    const totalRating = reviews.reduce(
      (accumulator, currentReview) => accumulator + currentReview.ratingPoint,
      0
    );

    return totalRating / reviews.length;
  };

  //button-sort-rate-1
  const sortFutsalsRate = (sortType: string) => {
    const sortedFutsals = [...futsals];
    if (sortType === "highToLowRate") {
      sortedFutsals.sort(
        (a, b) =>
          calculateAverageRating(b.reviews) - calculateAverageRating(a.reviews)
      );
    } else if (sortType === "lowToHighRate") {
      sortedFutsals.sort(
        (a, b) =>
          calculateAverageRating(a.reviews) - calculateAverageRating(b.reviews)
      );
    }
    setFutsals(sortedFutsals);
  };
  //button-sort-rate-2
  const handleSortRateBtn = (sortType: string) => {
    // Apply all filters
    applyFilters();

    // Clear other filters
    setPriceButtonClicked("");
    setRateButtonClicked(sortType);
    setSort(sortType);

    // Sort based on rating
    sortFutsalsRate(sortType);
  };

  //checkbox selection
  const handleStarClick = (starCount: number) => {
    setRatingFilter(true);

    // Check if the rating is already selected
    if (selectedRatings.includes(starCount)) {
      // If selected, remove it
      const updatedRatings = selectedRatings.filter(
        (rating) => rating !== starCount
      );
      setSelectedRatings(updatedRatings);
      console.log("updated ratings ", updatedRatings);
    } else {
      // If not selected, add it
      setSelectedRatings([...selectedRatings, starCount]);
      console.log("newly added ", [...selectedRatings, starCount]);
    }
  };

  const handlePriceClear = () => {
    // Clear the price filter
    setPriceButtonClicked("");
    // Remove the price filter from the futsals
    applyFilters();
  };

  const handleRateClear = () => {
    setRateButtonClicked("");
    setSelectedRatings([]);
    setSort("");
    applyFilters();
  };

  const handleSportClear = () => {
    setSportButtonClicked("");
    applyFilters();
  };

  //Toggles-for-icon-change
  const togglePrice = () => {
    setPriceFilter(!priceFilter);
    toggleArrowDirection("price");
  };
  const toggleRating = () => {
    setRatingFilter(!ratingFilter);
    toggleArrowDirection("rating");
  };
  const toggleCourtType = () => {
    setshowCourtType(!showCourtType);
    toggleArrowDirection("sports");
  };

  const toggleArrowDirection = (category: "rating" | "sports" | "price") => {
    setArrowDirections((prevDirections) => ({
      ...prevDirections,
      [category]: prevDirections[category] === "down" ? "forward" : "down",
    }));
  };

  const toggleMobileSidebar = () => {
    setMobileSidebarVisible(!mobileSidebarVisible);
  };


  const togglePcSidebar = () => {
    setPcSidebarVisible(!pcSidebarVisible);
  };
  //location-arrow
  const handleArrowClick = (direction: "forward" | "back") => {
    if (direction === 'forward' && startIndex + buttonsToShow < buttons.length) {
      setStartIndex(startIndex + buttonsToShow);
    } else if (direction === 'back' && startIndex - buttonsToShow >= 0) {
      setStartIndex(startIndex - buttonsToShow);
    }
  };


  //get unique district form backend
  // const getUniqueLocations = (): string[] => {
  //   const uniqueLocationsSet = new Set(originalFutsals.map((court) => court.district));
  //   return Array.from(uniqueLocationsSet);
  // };
  
  return (
    <>
      <div className="view-all-futsal-header">
        <h2>ALL SPORTS COURTS</h2>
        <div className="view-all-futsals-card-filters">
          <MdArrowBackIosNew onClick={() => handleArrowClick("back")} />
          <div className="view-all-buttons-container">
            {buttons
              .slice(startIndex, startIndex + buttonsToShow)
              .map((location, index) => (
                <button
                  key={index}
                  className={`view-all-select-location-btn ${
                    selectedButton === location ? "active" : ""
                  }`}
                  onClick={() => handleLocationClick(location)}
                >
                  {location}
                </button>
              ))}
          </div>
          <MdArrowForwardIos onClick={() => handleArrowClick("forward")} />
        </div>
      </div>

      <div className={`view-all-futsals`}>
        <div
          className={`toggle-menu-filter ${
            pcSidebarVisible ? "visible-pc" : "hide-pc"
          }`}
        >
          <div className={`filter-toggle`} onClick={togglePcSidebar}>
            <p>Filters</p>
          </div>
          <div className={`view-all-futsals-sidebar`}>
            <div className="filter-container-1">
              <div className="filter-header" onClick={toggleCourtType}>
                <p>
                  {arrowDirections.sports === "down" ? (
                    <IoIosArrowForward />
                  ) : (
                    <IoIosArrowDown />
                  )}
                  Sport
                </p>
                <p onClick={handleSportClear}>Clear</p>
              </div>
              {showCourtType && (
                <div className="select-filter-btns">
                  {getUniqueCourtTypes().map((courtType, index) => (
                    <button
                      key={index}
                      value={courtType}
                      onClick={() => courtBtnClick(courtType)}
                      className={`select-filter-btn ${
                        sportButtonClicked === courtType ? "active" : ""
                      }`}
                    >
                      {courtType}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className="filter-container-1">
              <div className="filter-header" onClick={togglePrice}>
                <p>
                  {arrowDirections.price === "down" ? (
                    <IoIosArrowForward />
                  ) : (
                    <IoIosArrowDown />
                  )}
                  Price
                </p>
                <p onClick={handlePriceClear}>Clear</p>
              </div>
              {priceFilter && (
                <div className="select-filter-btns">
                  <button
                    onClick={() => handleSortPriceBtn("highToLow")}
                    className={`select-filter-btn 
                  ${priceButtonClicked === "highToLow" ? "active" : ""}
                  `}
                  >
                    High to Low
                  </button>
                  <button
                    onClick={() => handleSortPriceBtn("lowToHigh")}
                    className={`select-filter-btn ${
                      priceButtonClicked === "lowToHigh" ? "active" : ""
                    }`}
                  >
                    Low to High
                  </button>
                </div>
              )}
            </div>
            <div className="filter-container-1">
              <div className="filter-header" onClick={toggleRating}>
                <p>
                  {arrowDirections.rating === "down" ? (
                    <IoIosArrowForward />
                  ) : (
                    <IoIosArrowDown />
                  )}
                  Rating
                </p>
                <p onClick={handleRateClear}>Clear</p>
              </div>
              {ratingFilter && (
                <>
                  <div className="select-filter-btns">
                    <button
                      className={`select-filter-btn ${
                        rateButtonClicked === "highToLowRate" ? "active" : ""
                      }`}
                      onClick={() => handleSortRateBtn("highToLowRate")}
                    >
                      High to Low
                    </button>
                    <button
                      onClick={() => handleSortRateBtn("lowToHighRate")}
                      className={`select-filter-btn ${
                        rateButtonClicked === "lowToHighRate" ? "active" : ""
                      }`}
                    >
                      Low to High
                    </button>
                  </div>
                  <div className="filters-stars">
                    <form className="rating-form">
                      <label className="container">
                        <input
                          type="checkbox"
                          name="rating"
                          value="oneTimeBooking"
                          onChange={() => handleStarClick(5)}
                          checked={selectedRatings.includes(5)}
                        />
                        <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
                        <AiFillStar /> <AiFillStar />
                        <span className="checkmark1"></span>
                      </label>
                      <label className="container">
                        <input
                          type="checkbox"
                          name="rating"
                          value="oneTimeBooking"
                          onChange={() => handleStarClick(4)}
                          checked={selectedRatings.includes(4)}
                        />
                        <AiFillStar />
                        <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
                        <AiOutlineStar />
                        <span className="checkmark1"></span>
                      </label>
                      <label className="container">
                        <input
                          type="checkbox"
                          name="rating"
                          value="oneTimeBooking"
                          onChange={() => handleStarClick(3)}
                          checked={selectedRatings.includes(3)}
                        />
                        <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
                        <AiOutlineStar /> <AiOutlineStar />
                        <span className="checkmark1"></span>
                      </label>
                      <label className="container">
                        <input
                          type="checkbox"
                          name="rating"
                          value="oneTimeBooking"
                          onChange={() => handleStarClick(2)}
                          checked={selectedRatings.includes(2)}
                        />
                        <AiFillStar /> <AiFillStar /> <AiOutlineStar />{" "}
                        <AiOutlineStar /> <AiOutlineStar />
                        <span className="checkmark1"></span>
                      </label>
                      <label className="container">
                        <input
                          type="checkbox"
                          name="rating"
                          value="oneTimeBooking"
                          onChange={() => handleStarClick(1)}
                          checked={selectedRatings.includes(1)}
                        />
                        <AiFillStar /> <AiOutlineStar /> <AiOutlineStar />{" "}
                        <AiOutlineStar /> <AiOutlineStar />
                        <span className="checkmark1"></span>
                      </label>
                    </form>
                  </div>
                </>
              )}
            </div>
            {/* </div> */}
          </div>
        </div>

        {!mobileSidebarVisible && (
          <div className="filter-mobile" onClick={toggleMobileSidebar}>
            <GrFilter />
          </div>
        )}

          <div
            className={`view-all-futsals-sidebar-mobile ${
              mobileSidebarVisible ? "visible" : "hidden"
            }`}
          >
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <h2>Filters</h2>
              <IoClose
                style={{ marginTop: "20px", fontSize: "24px" }}
                onClick={toggleMobileSidebar}
                size={30}
              />
            </div>
            <div className="filter-container">
              <div className="filter-container-1">
                <div className="filter-header" onClick={toggleCourtType}>
                  <p>
                    {arrowDirections.sports === "down" ? (
                      <IoIosArrowForward style={{ marginRight: "5px" }} />
                    ) : (
                      <IoIosArrowDown style={{ marginRight: "5px" }} />
                    )}
                    Sport
                  </p>
                  <p onClick={handleSportClear} style={{ fontSize: "12px" }}>
                    Clear
                  </p>
                </div>
                {showCourtType && (
                  <div className="select-filter-btns">
                    {getUniqueCourtTypes().map((courtType, index) => (
                      <button
                        key={index}
                        value={courtType}
                        onClick={() => courtBtnClick(courtType)}
                        className={`select-filter-btn ${
                          sportButtonClicked === courtType ? "active" : ""
                        }`}
                      >
                        {courtType}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              <div className="filter-container-1">
                <div className="filter-header" onClick={togglePrice}>
                  <p>
                    {arrowDirections.price === "down" ? (
                      <IoIosArrowForward style={{ marginRight: "5px" }} />
                    ) : (
                      <IoIosArrowDown style={{ marginRight: "5px" }} />
                    )}{" "}
                    Price
                  </p>
                  <p onClick={handlePriceClear} style={{ fontSize: "12px" }}>
                    Clear
                  </p>
                </div>
                {priceFilter && (
                  <div className="select-filter-btns">
                    <button
                      onClick={() => handleSortPriceBtn("highToLow")}
                      className={`select-filter-btn
                                      ${
                                        priceButtonClicked === "highToLow"
                                          ? "active"
                                          : ""
                                      }
                                      `}
                    >
                      High to Low
                    </button>
                    <button
                      onClick={() => handleSortPriceBtn("lowToHigh")}
                      className={`select-filter-btn ${
                        priceButtonClicked === "lowToHigh" ? "active" : ""
                      }`}
                    >
                      Low to High
                    </button>
                  </div>
                )}
              </div>
              <div className="filter-container-1">
                <div className="filter-header" onClick={toggleRating}>
                  <p>
                    {arrowDirections.rating === "down" ? (
                      <IoIosArrowForward style={{ marginRight: "5px" }} />
                    ) : (
                      <IoIosArrowDown style={{ marginRight: "5px" }} />
                    )}
                    Rating
                  </p>
                  <p onClick={handleRateClear} style={{ fontSize: "12px" }}>
                    Clear
                  </p>
                </div>
                {ratingFilter && (
                  <>
                    <div className="select-filter-btns">
                      <button
                        onClick={() => handleSortRateBtn("highToLowRate")}
                        className={`select-filter-btn ${
                          rateButtonClicked === "highToLowRate" ? "active" : ""
                        }`}
                      >
                        High to Low
                      </button>
                      <button
                        onClick={() => handleSortRateBtn("lowToHighRate")}
                        className={`select-filter-btn ${
                          rateButtonClicked === "lowToHighRate" ? "active" : ""
                        }`}
                      >
                        Low to High
                      </button>
                    </div>
                    <div className="filters-stars">
                      <form className="rating-form">
                        <label className="container">
                          <input
                            type="checkbox"
                            name="rating"
                            value="oneTimeBooking"
                            onChange={() => handleStarClick(5)}
                            checked={selectedRatings.includes(5)}
                          />
                          <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
                          <AiFillStar /> <AiFillStar />
                          <span className="checkmark1"></span>
                        </label>
                        <label className="container">
                          <input
                            type="checkbox"
                            name="rating"
                            value="oneTimeBooking"
                            onChange={() => handleStarClick(4)}
                            checked={selectedRatings.includes(4)}
                          />
                          <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
                          <AiFillStar /><AiOutlineStar />
                          <span className="checkmark1"></span>
                        </label>
                        <label className="container">
                          <input
                            type="checkbox"
                            name="rating"
                            value="oneTimeBooking"
                            onChange={() => handleStarClick(3)}
                            checked={selectedRatings.includes(3)}
                          />
                          <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
                          <AiOutlineStar /> <AiOutlineStar />
                          <span className="checkmark1"></span>
                        </label>
                        <label className="container">
                          <input
                            type="checkbox"
                            name="rating"
                            value="oneTimeBooking"
                            onChange={() => handleStarClick(2)}
                            checked={selectedRatings.includes(2)}
                          />
                          <AiFillStar /> <AiFillStar /> <AiOutlineStar />{" "}
                          <AiOutlineStar /> <AiOutlineStar />
                          <span className="checkmark1"></span>
                        </label>
                        <label className="container">
                          <input
                            type="checkbox"
                            name="rating"
                            value="oneTimeBooking"
                            onChange={() => handleStarClick(1)}
                            checked={selectedRatings.includes(1)}
                          />
                          <AiFillStar /> <AiOutlineStar /> <AiOutlineStar />{" "}
                          <AiOutlineStar /> <AiOutlineStar />
                          <span className="checkmark1"></span>
                        </label>
                      </form>
                    </div>
                  </>
                )}
              </div>
              <button
                className="filter-apply-btn"
                onClick={toggleMobileSidebar}
              >
                Apply
              </button>
            </div>
          </div>


        <div className="view-all-futsals-card-container">
          <div className="view-all-futsals-cards">
            {isLoaded
              ? // Display skeleton loader while fetching data
                [...Array(9)].map((_, index) => (
                  <ViewAllCourtCardSkeleton key={index} />
                ))
              : (() => {
                  const filteredFutsals = futsals.filter(
                    (futsal) => futsal.district === selectedLocation
                  );

                  return filteredFutsals.length > 0 ? (
                    filteredFutsals.map((futsal, index) => (
                      <ViewAllFutsalCard
                        key={index}
                        name={futsal.name}
                        place={futsal.place}
                        price={getCurrentPrice(futsal.pricePerHour)}
                        address={futsal.address}
                        futsalId={futsal.futsalId}
                        ratingPoint={calculateAverageRating(futsal.reviews).toFixed(1)}
                        review={futsal.reviews.length}
                      />
                    ))
                  ) : (
                    <div className="no-futsal-container">
                      <img src={NoFutsal}/>
                    </div>
                  );
                  
                })()}
          </div>
        </div>
      </div>

      {/* district-btn-from-backend */}
      {/* <div>
        {getUniqueLocations().map((district, index) => (
          <button
            key={index}
            onClick={() => handleLocationClick(district)}
            className={`district-button ${
              selectedLocation === district ? "active" : ""
            }`}
          >
            {district}
          </button>
        ))}
      </div> */}
    </>
  );
}

export default ViewAllFutsal;
