import React from "react";
import Skeleton from "react-loading-skeleton";
import "../skeletonCss/CourtSkeletonCss.css"
import 'react-loading-skeleton/dist/skeleton.css'


const CourtSkeleton = () => {
    return (
        <>
            <div className="courtSkeleton-container" >
                <Skeleton className="court-image-skeleton" width={window.innerWidth >= 1024 ? 210:143} height={window.innerWidth >= 1024 ? 150:120} />
                <div className="text-skeleton-container">
                    <div className="text-name-container">
                        <Skeleton className="court-name-skeleton"  width={window.innerWidth >= 1024 ? 120:80} height={window.innerWidth >= 1024 ? 30:17} />
                    </div>
                    <div className="text-price-container">
                        <Skeleton className="court-rating-skeleton"  width={window.innerWidth >= 1024 ? 90:60} height={window.innerWidth >= 1024 ? 20:15} />
                        <Skeleton className="court-price-skeleton"  width={window.innerWidth >= 1024 ? 90:60} height={window.innerWidth >= 1024 ? 20:15} />
                    </div>

                </div>
                {/*<Skeleton className="futsal-body"/>*/}
            </div>
        </>
    );
};

export default CourtSkeleton;
