
import {Routes, Route, useNavigate, createSearchParams} from 'react-router-dom';
import React from 'react'
import futsal1 from '../../../asserts/futsal-1.jpg';
import { AiFillStar } from 'react-icons/ai'
import {useFutsalId} from "../../../reducers/ui/FutsalIdHook";


function ViewAllFutsalCard(props: any) {
  let [futsalId,setFutsalId]=useFutsalId();
  const navigate = useNavigate();

  // function navigateToFutsalPage(futsalId:number){
  //   console.log(futsalId)
  //   setFutsalId(futsalId)
  //   navigate({
  //     pathname: "/courts-section",
  //     search: createSearchParams({
  //       futsalId: props.futsalId,
  //     }).toString()
  //   })
  //
  // }
  function navigateToFutsalPage(futsalId:number) {
    console.log(futsalId);
    setFutsalId(futsalId);
    navigate(`/courts-section/${futsalId}`);
  }

  return (
    <div className="view-all-futsal-card-stroke">
    <div className='view-all-card' onClick={()=>navigateToFutsalPage(props.futsalId)}>
      <div className='view-all-card-img'>
        <img src={futsal1} alt='futsal-1' />
      </div>
      <div className='view-all-details'>
        <div className='futsal-name'>
          <h2 className="first-word-futsal-name-all">{props.name.split(" ")[0].toUpperCase()}</h2>
          <h2 className="second-word-futsal-name-all">{props.name.split(" ")[1].toUpperCase()} </h2>
          <p className='rating view-all-card-rate-mobile'>
          <p className='place'>{`${props.place}\u00A0\u00A0\u00A0`}</p>

              {/* {calculateAverageRating().toFixed(1)} <AiFillStar /> &nbsp; ({review.length}) */}
              <p className='rating-mobile'>{props.ratingPoint}<AiFillStar/> ({(props.review)})</p> 

            </p>
        </div>
        <hr className="line2"/>
        <div className='view-all-card-details'>
          <div className='futsal-details'>
            <p className='rating view-all-card-rate-pc'>
              {/* {calculateAverageRating().toFixed(1)} <AiFillStar /> &nbsp; ({review.length}) */}
              {props.ratingPoint}<AiFillStar/> &nbsp;({(props.review)})
            </p>
            <p className='view-all-card-rate-pc place'>{props.place}</p>

          </div>
          <div className='view-all-card-price'>
         
            <h4>Rs.{props.price}<span>/hr</span></h4>
          </div>
        </div>

      </div>
    </div>
    </div>


  );
}

export default ViewAllFutsalCard;