import React, {useEffect, useState} from "react";
import {DatepickerEvent,} from "@meinefinsternis/react-horizontal-date-picker";
import {Futsal} from "../../interfaces/Futsal";
import {endPoints} from "../../utils/endPoints";
import {axiosInstance} from "../../utils/axiosInstance";
import {useUser} from "../../reducers/ui/userHook";
import {useParams, useSearchParams} from "react-router-dom";
import moment from "moment-timezone";
import {
  calculatePerPrice,
  calculateTotalDuration,
  calculateTotalPrice,
  fetchAvailableTimeSlots,
  getStartAndEndTime,
  handleButtonClickTime,
} from "./helper-functions/HelperFunctions";
import CheckoutHeader from "../navigation/checkout-navigation/CheckoutHeader";
import DateTimeSelector from "./checkout-card/date-time-picker/DateTimeSelector";
import BookingSummary from "./checkout-card/booking-summery-card/BookingSummary";
import {useCookies} from "react-cookie";
import {toast} from "react-toastify";

interface Court {
  _id: string;
  courtName: string;
}
interface BookingSend {
  userName: string;
  email: string;
  futsalId: number;
  bookingTime: string | null;
  playStartTime: string | null;
  playEndTime: string | null;
  startTime: string;
  endTime: string;
  courtName: string;
  price: number;
  status: String;
}
function BookingType() {
  const { futsalId } = useParams();
  const [selectCourts, setSelectCourts] = useState<Court[]>([]); // Select courts as an array
  const [date, setDate] = React.useState(new Date());
  const [courts, setCourts] = useState<Court[]>([]);
  let [user, setUser] = useUser();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null); //date-picker
  const [selectedDateFormatted, setSelectedDateFormatted] = useState<
    string | null
  >(null);
  const [availability, setAvailability] = useState<any | null>({});
  const [selectedButton, setSelectedButton] = useState<string[]>([]);
  const [futsalData, setFutsalData] = useState<Futsal | null>();
  const [selectedCourt, setSelectedCourt] = useState<Court | null>(null);
  const [time, setTime] = useState(new Date());
  const [bookingData, setBookingData] = useState<BookingSend>({
    userName: "",
    email: "",
    futsalId: parseInt(futsalId!!),
    bookingTime:
      new Date(time.getTime() + 5.3 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, -5) + "Z",
    playStartTime: null,
    playEndTime: null,
    startTime: "",
    endTime: "",
    courtName: "",
    price: 0,
    status: "",
  });
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const[userEmail,setUserEmail]=useState();


  useEffect(() => {
    console.log("prevSelected:", selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    fetchFutsalData();
  }, []);

  useEffect(() => {
    const verifyCookie = async () => {
      const token=localStorage.getItem("token")

      if (!token) {
        toast.error("session expired")
      }
      const { data } = await axiosInstance.post(
          endPoints.USER_AUTHENTICATION,
          {token},
          { withCredentials: true }
      );
      const { status, user ,name} = data;
      setBookingData((prevData) => ({
        ...prevData,
        email: user,
        userName:name,
      }));
      setUserEmail(user)
      return status
          ?""
          :localStorage.removeItem("token");
    };
    verifyCookie();
  }, [cookies, removeCookie]);

  useEffect(() => {
    if (userEmail) {
      axiosInstance.get(`${endPoints.GET_USER}${userEmail}`)
          .then(response => {
            if (response.data.status === 'ok') {
              setUser(response.data.user);
            }
          })
          .catch(error => {
            console.error('Error fetching user details:', error);
          });
    }
  }, [userEmail]);

  //futsal data
  const fetchFutsalData = async () => {
    try {
      const endPoint = endPoints.GET_FUTSAL;
      const response = await axiosInstance.get(`${endPoint}${futsalId}`);
      const futsal = response.data.futsal;
      console.log(futsal.courts);
      if (futsal.courts && futsal.courts.length > 0) {
        setSelectCourts([futsal.courts[0]]);
        setSelectedCourt(futsal.courts[0]);
      }
      setCourts(futsal.courts);
      setFutsalData(futsal);
      console.log("get futsals1", response);
    } catch (error) {
      console.error("Error fetching futsal data:", error);
    }
  };



  const handleButtonClickCourt = (clickedCourt: any) => {
    setSelectCourts([clickedCourt]);
    setSelectedCourt(clickedCourt);

    setBookingData((prevData) => ({
      ...prevData,
      courtName: clickedCourt.courtName,
    }));
    setSelectedButton([]);
    setAvailability({});

    fetchAvailableTimeSlots(
        date,
        clickedCourt,
        setAvailability,
        futsalData,
        bookingData
    );


  };

  const handleChangeDatePick = (d: DatepickerEvent) => {
    const [startValue] = d;
    const sriLankanTime = new Date(
      startValue!.getTime() - startValue!.getTimezoneOffset() * 60000
    );

    fetchAvailableTimeSlots(
        sriLankanTime,
        selectedCourt,
        setAvailability,
        futsalData,
        bookingData
    );
    setDate(sriLankanTime);
    // Format date
    const formattedDate = sriLankanTime.toLocaleDateString("en-IN", {
      day: "numeric",
      month: "short",
      weekday: "short",
    });

    setSelectedDate(sriLankanTime);
    setSelectedDateFormatted(formattedDate);
    setSelectedButton([]);
    console.log("sriLankanTime", sriLankanTime, "formattedDate", formattedDate);
  };

  useEffect(() => {
    fetchFutsalData();
    // setBookingData((prevData) => ({
    //   ...prevData,
    //   email: user.email,
    //   userName: user.name,
    // }));
  }, [user]);


  const addBooking = async () => {
    try {
      const endPoint = endPoints.ADD_BOOKING;
      bookingData.status = "OneTimeBooking";
      console.log("BookedData", bookingData);
      const response = await axiosInstance.post(endPoint, bookingData);
      if (response.data && response.data.status === "ok") {
        console.log("Booking added successfully.");
        const updatedBookingData = { ...bookingData };

        // Add 7 days to both start and end times of the booking
        updatedBookingData.playStartTime = moment(
          updatedBookingData.playStartTime
        )
          .add(7, "days")
          .toISOString();
        updatedBookingData.playEndTime = moment(updatedBookingData.playEndTime)
          .add(7, "days")
          .toISOString();

        updatedBookingData.status = "hold";
        const secondResponse = await axiosInstance.post(
          endPoint,
          updatedBookingData
        );

        if (secondResponse.data && secondResponse.data.status === "ok") {
          console.log("Second booking added successfully.");
        } else {
          console.log(
            "Failed to add second booking or unexpected response:",
            secondResponse.data
          );
        }

        console.log("BookedData hold", updatedBookingData);
      } else {
        console.log(
          "Failed to add booking or unexpected response:",
          response.data
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedCourt != undefined) {
      setBookingData((prevData) => ({
        ...prevData,
        courtName: selectedCourt?.courtName,
      }));
    }
  }, [selectedCourt]);

  const times =
    selectedButton.length > 0 && getStartAndEndTime(selectedButton, date);

  useEffect(() => {
    console.log("user", user);
    console.log("booking Data", bookingData);
  }, [bookingData]);

  useEffect(() => {
    if (futsalData) {
      setBookingData((prevData) => ({
        ...prevData,
        price: calculateTotalPrice(futsalData, selectCourts, selectedButton),
      }));
    }
  }, [selectedButton]);

  useEffect(() => {
    if (times) {
      setBookingData((prevData) => ({
        ...prevData,
        startTime: times.formattedStartTime,
        endTime: times.formattedEndTime,
        playStartTime: times.playStartTime,
        playEndTime: times.playEndTime,
      }));
    }
  }, [selectedButton]);

  const handleClose = () => {
    window.history.back();
  };

  const handleNextBtn = () => {
    addBooking();
  };
  return (
    <>
      <CheckoutHeader
        stepNumber={2}
        stepTitle={"Select Date & Time"}
        handleClose={handleClose}
      />

      <div className="date-time-container">
        <DateTimeSelector
          courts={courts}
          selectedDate={selectedDate}
          availability={availability}
          selectedButton={selectedButton}
          selectCourts={selectCourts}
          handleButtonClickCourt={handleButtonClickCourt}
          handleButtonClickTime={handleButtonClickTime}
          setSelectedButton={setSelectedButton}
          handleChangeDatePick={handleChangeDatePick}
          user={user}
          method={"PermanentBooking"}
        />

        <BookingSummary
          selectedDate={selectedDate}
          futsalData={futsalData}
          selectedDateFormatted={selectedDateFormatted}
          selectedButton={selectedButton}
          selectCourts={selectCourts}
          calculateTotalDuration={calculateTotalDuration}
          calculatePerPrice={calculatePerPrice}
          calculateTotalPrice={calculateTotalPrice}
          handleNextBtn={handleNextBtn}
        />
      </div>
    </>
  );
}

export default BookingType;
