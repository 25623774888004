import React, {useState} from "react";
import { Futsal } from "../../interfaces/Futsal";
import { Review } from "../../interfaces/Review";
import { TbStarHalfFilled } from "react-icons/tb";
import { RxStarFilled } from "react-icons/rx";
import { FaRegStar } from "react-icons/fa";

interface CourtHeaderProps {
    futsal: Futsal;
    bookNowHandle: () => void;
    review: Review[];
}

const CourtHeader: React.FC<CourtHeaderProps> = ({
                                                     futsal,
                                                     bookNowHandle,
                                                     review,
                                                 }) => {
    const averageRating =
        review.length > 0
            ? review.reduce((sum, review) => sum + review.ratingPoint, 0) /
            review.length
            : 0;

    const generateStars = (averageRating: number) => {
        const stars = [];
        const fullStars = Math.floor(averageRating);
        for (let i = 0; i < fullStars; i++) {
            stars.push(<RxStarFilled  key={i} />);
        }
        const decimalPart = averageRating - fullStars;
        if (decimalPart > 0) {
            stars.push(<TbStarHalfFilled  key="half" />);
        }
        const remainingStars = 5 - stars.length;
        for (let i = 0; i < remainingStars; i++) {
            stars.push(<FaRegStar  key={`empty_${i}`} />);
        }
        return stars;
    };

    return (
        <div className="court-header-container">
            <div className="court-header-content">
                <h1>{futsal.name.toUpperCase()}</h1>
                <div className="mobile-court-name">
                    <h1>{futsal.name.split(" ")[0]?.toUpperCase()}</h1>
                    <h1>{futsal.name.split(" ")[1]?.toUpperCase()}</h1>
                </div>


                <button onClick={bookNowHandle}>Book Now</button>
            </div>
            <div className="court-header-extra-content">
                <ul>
                    <li className="review-li">
                        <span> {averageRating.toFixed(1)} &nbsp;</span>
                        <span className="reviews-stars-top">{generateStars(averageRating)}</span>
                        <span className="review-total">({review.length})</span>
                    </li>
                    <li className="address-li">
                        <span className="dot">•</span> {futsal.address}
                    </li>
                    <li>
                        <a href={futsal.url} target="_blank" rel="noopener noreferrer">
                            Get Direction
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default CourtHeader;
