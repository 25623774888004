import React from 'react';
import {
    Datepicker,
    DatepickerEvent,
} from "@meinefinsternis/react-horizontal-date-picker";
import { enIN } from "date-fns/locale";
import moment from "moment-timezone";
import {User} from "../../../../interfaces/User";

interface DateTimeBookingProps  {
    courts: any[];
    selectedDate: Date| null;
    availability: any | null;
    selectedButton: string[];
    selectCourts: any[];
    handleButtonClickCourt: (court: any) => void;
    handleButtonClickTime: (startTime: string, availability: any, selectedButton: string[], setSelectedButton: React.Dispatch<React.SetStateAction<string[]>>,method:string,user:User) => void;
    setSelectedButton: React.Dispatch<React.SetStateAction<string[]>>;
    handleChangeDatePick:(d: DatepickerEvent)=>void;
    user:User;
    method:string
}


const DateTimeBooking : React.FC<DateTimeBookingProps> = ({
                             courts,
                             selectedDate,
                             availability,
                             selectedButton,
                             selectCourts,
                             handleButtonClickCourt,
                             handleButtonClickTime,
                             setSelectedButton,
                             handleChangeDatePick,
                             user,
                             method
                         }) => {
    return (
        <div className="date-time-booking">
            <h3>Courts</h3>
            {courts.map((court, index) => (
                <button
                    key={index}
                    onClick={() => handleButtonClickCourt(court)}
                    className={`date-time-booking-court-btn ${
                        selectCourts.some(
                            (selectedCourt) => selectedCourt._id === court._id
                        )
                            ? "selected"
                            : ""
                    }`}
                >
                    {court.courtName}
                </button>
            ))}

            <h3>Date & Time</h3>
            <Datepicker
                locale={enIN} // Assuming enIN is defined elsewhere
                startValue={selectedDate}
                endValue={selectedDate}
                onChange={handleChangeDatePick} // Assuming handleChangeDatePick is defined elsewhere
            />
            {availability && (
                <div className="time-scroll-container">
                    {Object.keys(availability)
                        .sort((a, b) => {
                            const timeA = moment(a, "HH:mm").valueOf();
                            const timeB = moment(b, "HH:mm").valueOf();
                            return timeA - timeB;
                        })
                        .map((startTime, index) => (
                            <button
                                key={index}
                                onClick={() =>
                                    handleButtonClickTime(
                                        startTime,
                                        availability,
                                        selectedButton,
                                        setSelectedButton,
                                        method,
                                        user
                                    )
                                }
                                className={`date-time-booking-court-btn ${
                                    selectedButton.includes(
                                        `${moment(startTime, "HH:mm").format(
                                            "hh:mm A"
                                        )} - ${moment(
                                            availability[startTime].endTime,
                                            "HH:mm"
                                        ).format("hh:mm A")}`
                                    )
                                        ? "selected"
                                        : "not-selected"
                                }
                                ${
                                    availability[startTime].type === "hold"
                                        ? "hold-booking"
                                        : ""
                                }`}
                                style={{
                                    backgroundColor:
                                        availability[startTime].type === "hold"
                                            ? "orange"
                                            : "",
                                    color:
                                        availability[startTime].type === "hold"
                                            ? "white"
                                            : "",
                                    border:
                                        availability[startTime].type === "hold"
                                            ? "orange"
                                            : "",
                                    cursor:
                                        availability[startTime].type === "hold"
                                            ? "not-allowed"
                                            : "",
                                }}
                            >
                                {`${moment(startTime, "HH:mm").format(
                                    "hh:mm A"
                                )} - ${moment(
                                    availability[startTime].endTime,
                                    "HH:mm"
                                ).format("hh:mm A")}`}
                            </button>
                        ))}
                </div>
            )}
        </div>
    );
}

export default DateTimeBooking;
