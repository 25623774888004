import {endPoints} from "../utils/endPoints";
import {axiosInstance} from "../utils/axiosInstance";


declare global {
    interface Window {
        payhere: {
            startPayment(payment: any): void;
            onCompleted(orderId: string): void;
            onDismissed(): void;
            onError(error: any): void;
        };
    }
}

interface BookingSend {
    userName: string;
    email: string;
    futsalId: number;
    bookingTime: string | null;
    playStartTime: string | null;
    playEndTime: string | null;
    startTime: string;
    endTime: string;
    courtName: string;
    price: number;
    status: String;
}



const PaymentModal = ({ orderId, name, amount,hash,email,phone,bookingData }: { orderId: string, name: string, amount: number,hash:string,email:string,phone:string,bookingData:BookingSend }) => {

    var payment = {
        sandbox: true,
        merchant_id: '1226184',
        return_url: 'http://sample.com/return',
        cancel_url: 'http://sample.com/cancel',
        notify_url: 'http://sample.com/notify',
        order_id: orderId,
        items: "Turf-Court2",
        amount: amount,
        currency: 'LKR',
        first_name: name,
        last_name: 'Ahamed',
        email: email,
        phone: phone,
        address: 'No.1, Galle Road',
        city: 'Colombo',
        country: 'Sri Lanka',
        delivery_address: '',
        delivery_city: '',
        delivery_country: 'Sri Lanka',
        custom_1: '',
        custom_2: '',
        hash:hash
    };


    window.payhere.onCompleted =async function onCompleted(orderId) {
        console.log("Payment completed. OrderID:" + orderId);
        try {
            const endPoint = endPoints.ADD_BOOKING;
            const response = await axiosInstance.post(endPoint, bookingData);
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    window.payhere.onDismissed = function onDismissed() {
        console.log("Payment dismissed");
    };

    window.payhere.onError = function onError(error) {
        console.log("Error:"  + error);
    };

    function pay(){
        window.payhere.startPayment(payment);
    }

    return <button onClick={pay}>Pay with Payhere</button>;
};

export default PaymentModal;
