import players from "../../asserts/football-players.png";
const WelcomeToGearUp = () => {
    const joinWhatsAppCommunity = () => {
        const whatsappLink = "https://chat.whatsapp.com/Jw737xAw7rm3VgPn7mdi7U";
        window.location.href = whatsappLink;
    };


return (
    <div className="welcome-to-gearup-container">
      <div className="content-container">
          <div className="gearup-players-container">
              <img src={players} alt="players"/>

          </div>
          <div className="wording-container">
          <h3>Hello</h3>
          <h1>WE WELCOME</h1>
          <h1>
            YOU TO <span>GEARUP</span>
          </h1>
          <hr />
          <p>
            At GearUp, we're not just a platform; we're a community of sports
            enthusiasts dedicated to transforming the way you book sports
            facilities. Our passion for active lifestyles drives on this journey
            and discover a new level of convenience, choice,and connection in
            the world of sports.!
          </p>
          <button onClick={joinWhatsAppCommunity}>JOIN NOW</button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeToGearUp;
